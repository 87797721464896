import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";

import {
  AppModule,
  Bot,
  BotCustomization,
  BotPlan,
  BotSettings,
  CannedResponse,
  CannedResponseMedia,
  CustomPlanValues,
  Feature,
  Label,
  LiveChatCard,
  // LiveUsage,
  MediaType,
  PlanType,
  SubscriptionStatus,
  User,
  UserType,
  WhatsappTemplate,
} from "../models";

import { logError } from "../services/logging";
import {
  getBotSettings,
  linkBot,
  postBotSettings,
  saveBotAgentsSchedule,
  sendEvents,
} from "../services/chathub";

import auth from "./auth";
import users from "./users";
import {
  deleteFiles,
  FirebaseSubscriber,
  uploadFiles,
  features,
} from "./utils";
import audit from "./audit";
import { toast } from "bulma-toast";
import { t } from "i18next";
import locale from "../constants/locale";
import restaurants from "./restaurants";
import workspaces from "./workspaces";

class BotsStore {
  private db: firebase.firestore.Firestore;
  private lastInterval?: NodeJS.Timeout;
  private botSubscriber?: FirebaseSubscriber;

  selectedBot?: Bot;
  botSelected: boolean = false;
  subscriptionLoaded = false;
  // subscription?: Subscription;

  cannedResponses: CannedResponse[] = [];
  usersInCannedResponses: User[] = [];
  labels: Label[] = [];
  templates: WhatsappTemplate[] = [];
  botSettingsKey = 0;
  botSettings = {} as any;
  botSettingsLoaded = false;
  activeLiveChatCards: LiveChatCard[] = [];

  constructor() {
    makeAutoObservable(this, { updateUserLastActivity: false });

    this.db = firebase.firestore();
  }

  clearSelectedBot = () => {
    this.setSelectedBot(undefined);
    this.botSelected = false;
    this.activeLiveChatCards = [];
    this.botSettingsLoaded = false;
    this.botSettings = {};
    this.setCannedResponses([]);
    this.setTemplates([]);
  };

  selectBot = async (data?: { id: number; key: string; password: string }) => {
    if (!data) {
      this.setSelectedBot(undefined);
      return;
    }

    try {
      const bot =
        (await linkBot(data.id, data.key, data.password)) || undefined;

      if (this.botSubscriber) {
        this.botSubscriber.unsubscribe();
        this.botSubscriber = undefined;
      }

      if (bot) {
        const snap = await this.db.collection("bots").doc(`${bot.id}`).get();
        const snapData = snap.data();

        this.setSelectedBot({
          ...bot,
          ...snapData,
          settings: {
            ...snapData?.settings,
            ...bot.settings,
          },
        });

        this.botSubscriber = {
          id: bot.id,
          unsubscribe: this.db
            .collection("bots")
            .doc(`${bot.id}`)
            .onSnapshot((snap) => {
              const snapData = snap.data();

              if (!snapData || !this.selectedBot) {
                return;
              }

              if (
                this.selectedBot.balance &&
                this.selectedBot.balance !== snapData.balance
              ) {
                this.selectedBot.balance = snapData.balance;
                return;
              }

              this.setSelectedBot({
                ...this.selectedBot,
                ...snapData,
                settings: {
                  ...this.selectedBot.settings,
                  ...snapData.settings,
                },
              });
            }),
        };
      }

      if (this.selectedBot?.id && auth?.user?.id) {
        // users.loadUsers(this.selectedBot.id);
        // await users.loadAllUsers(this.selectedBot.id);
        // await aITeams.loadAITeams();
        const cards =
          (await users.loadLiveChatCards(
            this.selectedBot.id,
            this.selectedBot.key,
            this.selectedBot.password
          )) ?? [];
        this.activeLiveChatCards =
          cards.filter((card) => {
            if (
              [UserType.Root, UserType.Administrator].includes(auth.user?.type!)
            ) {
              return true;
            }
            if (auth.user && this.selectedBot?.id) {
              return users
                .getAssignedGroupIdsOfUser(auth.user, this.selectedBot.id)
                .includes(card.id!);
            }
            return false;
          }) ?? [];
        await restaurants.selectRestaurant(String(this.selectedBot.id));
      }

      // await users.updateUserInChathub(auth.user?.id || "");

      this.botSelected = true;
    } catch (error) {
      logError(error);
    }
  };

  canBotAddAgents = async (botId: number) => {
    const bot = await this.db.collection("bots").doc(`${botId}`).get();
    const botData = bot.data() as Bot;

    if (
      !botData ||
      botData.plan?.type === undefined ||
      botData.plan.type === PlanType.Third
    ) {
      return true;
    }

    const res = await this.db
      .collection("users")
      .where("botsIds", "array-contains", botId)
      .where("type", ">=", UserType.Supervisor)
      .get();

    const defBaseAgents = {
      [PlanType.First]: Number(process.env.REACT_APP_FIRST_PLAN_AGENTS),
      [PlanType.Second]: Number(process.env.REACT_APP_SECOND_PLAN_AGENTS),
      [PlanType.Third]: Number(process.env.REACT_APP_EXTRA_AGENT_PRICE),
    };

    const count = res.size;
    const baseAgents =
      botData.customPlanValues?.[botData.plan.type].baseAgents ||
      defBaseAgents[botData.plan.type];

    return count < botData.plan.agents + baseAgents;
  };

  loadCannedResponses = async () => {
    if (!this.selectedBot) {
      return;
    }

    try {
      this.setCannedResponses([]);

      const snap = await this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("responses")
        .orderBy("createdAt", "desc")
        .get();

      this.setCannedResponses(
        snap.docs.map((m) => {
          const note = m.data() as CannedResponse;
          note.id = m.id;
          return note;
        })
      );
    } catch (error) {
      logError(error);
    }
  };

  addCannedResponse = async (
    content: string,
    groupIds: number[],
    media?: { type: MediaType; file?: Blob; name?: string }[],
    category?: string
  ) => {
    if (!this.selectedBot || !auth.user) {
      return;
    }

    try {
      const doc = this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("responses")
        .doc();

      let uploadedMedia: CannedResponseMedia[] | undefined = media as any;

      if (media && media?.[0]?.type !== "emailTemplate") {
        const links = await uploadFiles(media.map((m) => m.file as File));
        uploadedMedia = links.map((m, i) => ({
          type: media[i].type,
          url: m,
          name: media[i].name,
        }));
      }

      const note: CannedResponse = {
        id: doc.id,
        category,
        content,
        media: uploadedMedia,
        groupIds,
        createdBy: auth.user.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp() as any,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp() as any,
      };

      await doc.set(note);

      const freshNote = (await doc.get()).data() as CannedResponse;
      freshNote.id = doc.id;

      audit.logEvent("canned_response_added", {
        id: doc.id,
        doc: {
          ...freshNote,
          // @ts-ignore
          createdAt: freshNote.createdAt.toDate(),
          // @ts-ignore
          updatedAt: freshNote.updatedAt.toDate(),
        },
        botId: this.selectedBot.id,
      });

      this.setCannedResponses([...this.cannedResponses, freshNote]);
      this.setUsersInCannedResponses(
        this.usersInCannedResponses.some((s) => s.id === auth.user?.id)
          ? this.usersInCannedResponses
          : [...this.usersInCannedResponses, auth.user]
      );
    } catch (error) {
      logError(error);
    }
  };

  updateCannedResponse = async (
    id: string,
    content: string,
    groupIds: number[],
    media?: { type: MediaType; file: Blob; name?: string }[],
    category?: string
  ) => {
    if (!this.selectedBot) {
      return;
    }

    const found = this.cannedResponses.find((s) => s.id === id);
    if (!found) {
      return;
    }

    try {
      const doc = this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("responses")
        .doc(id);

      let uploadedMedia: CannedResponseMedia[] = [];

      if (media) {
        try {
          await deleteFiles(
            found.media
              ?.filter((f) => f.type !== "emailTemplate")
              ?.map((m) => decodeURIComponent(m.url)) || []
          );
        } catch (e) {}

        if (media[0].type !== "emailTemplate") {
          const links = await uploadFiles(media.map((m) => m.file as File));

          uploadedMedia = links.map((m, i) => ({
            type: media[i].type,
            url: m,
            name: media[i].name,
          }));
        } else {
          uploadedMedia = media as any;
        }
      }

      await doc.set(
        {
          category,
          content,
          media: uploadedMedia,
          groupIds,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      );

      const freshNote = (await doc.get()).data() as CannedResponse;
      freshNote.id = doc.id;

      audit.logEvent("canned_response_updated", {
        id: doc.id,
        doc: {
          ...freshNote,
          // @ts-ignore
          createdAt: freshNote.createdAt.toDate(),
          // @ts-ignore
          updatedAt: freshNote.updatedAt.toDate(),
        },
        botId: this.selectedBot.id,
      });

      this.setCannedResponses(
        this.cannedResponses.map((f) => (f.id === id ? freshNote : f))
      );
    } catch (error) {
      logError(error);
    }
  };

  deleteCannedResponse = async (id: string) => {
    if (!this.selectedBot) {
      return;
    }

    const found = this.cannedResponses.find((s) => s.id === id);
    if (!found) {
      return;
    }

    try {
      await this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("responses")
        .doc(id)
        .delete();

      audit.logEvent("canned_response_deleted", {
        id,
        doc: { ...(this.cannedResponses.find((f) => f.id === id) || {}) },
        botId: this.selectedBot.id,
      });

      this.setCannedResponses(this.cannedResponses.filter((f) => f.id !== id));

      if (found.media) {
        await deleteFiles(found.media.map((m) => m.url));
      }
    } catch (error) {
      logError(error);
    }
  };

  loadUsersInCannedResponses = async () => {
    if (!this.cannedResponses.length) {
      return;
    }

    try {
      const usersIdsSet = new Set<string>();
      this.cannedResponses.forEach((f) => usersIdsSet.add(f.createdBy));
      const usersIdsArray = [...usersIdsSet];

      const users: User[] = [];

      for (let index = 0; index < Math.ceil(usersIdsSet.size / 10); index++) {
        const snap = await this.db
          .collection("users")
          .where("id", "in", usersIdsArray.slice(index * 10, (index + 1) * 10))
          .get();

        users.push(...snap.docs.map((m) => m.data() as User));
      }

      this.setUsersInCannedResponses(users);
    } catch (error) {
      logError(error);
    }
  };

  loadLabels = async () => {
    if (!this.selectedBot) {
      return;
    }

    try {
      this.setLabels([]);

      const snap = await this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("labels")
        .get();

      this.setLabels(
        snap.docs
          .map((m) => m.data() as Label)
          .filter((f) => !f.deletedAt)
          .sort((a, b) => a.name.localeCompare(b.name))
      );

      return this.labels;
    } catch (error) {
      logError(error);
    }
  };

  addLabel = async (name: string, color: string, bypassPermission = false) => {
    if (!this.selectedBot) {
      return null;
    }

    const found = this.labels.find(
      (s) => s.name.toLowerCase() === name.toLowerCase()
    );

    if (found) {
      return found.id;
    }

    try {
      if (
        !bypassPermission &&
        !auth.userHasAdminAccess &&
        auth.getPermissions()?.restrictLabelCreation
      ) {
        throw new Error("labels-creation/restricted");
      }
      const doc = this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("labels")
        .doc();

      const label: Label = {
        id: doc.id,
        name,
        color,
      };

      await doc.set(label);

      this.setLabels(
        [...this.labels, label].sort((a, b) => a.name.localeCompare(b.name))
      );

      audit.logEvent("label_added", {
        id: label.id,
        doc: label,
        botId: this.selectedBot.id,
      });

      return label.id;
    } catch (error) {
      logError(error);
      throw error;
    }
  };

  deleteLabel = async (id: string) => {
    if (!this.selectedBot) {
      return;
    }

    try {
      const doc = this.db
        .collection("bots")
        .doc(String(this.selectedBot.id))
        .collection("labels")
        .doc(id);

      await doc.update({
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      audit.logEvent("label_deleted", {
        id,
        doc: { ...(this.labels.find((f) => f.id === id) || {}) },
        botId: this.selectedBot.id,
      });

      this.setLabels(this.labels.filter((f) => f.id !== id));
    } catch (error) {
      logError(error);
      return;
    }
  };

  saveBotSettings = async ({
    message,
    dialog,
    agentsSchedule,
    livecardSchedule,
    timezone,
    usersAllowedToAssignChats,
    scheduledChatClosingDialog,
    scheduledChatClosingDialogType,
    scheduledChatClosingDialogAt,
    maxCallsAssigned,
    maxChatsAssigned,
    enableAutomaticChatAcceptance,
    enableAutoAssignRequestOnNextDay,
    chatAssignmentType,
    autoAssignChatUserTypes,
    queuePositionEnabled,
    queuePositionDialog,
    chatTransferredTimeout,
    autoAssignDealUserTypes,
    autoOpenChat360ModalWhenAgentConnects,
    autoAssignRoundRobinChat,
    roundRobinChatTimeout,
    disableACMForChatTransfer,
    disableCCDForChatTransfer,
    disableChatHistory,
    guestModeEnabled,
    dealUpdateRequired,
    backupLivecardId,
    backupLivecardTimeout,
  }: BotSettings & { message: string; dialog: number }) => {
    if (!this.selectedBot) {
      return;
    }

    try {
      await saveBotAgentsSchedule(
        this.selectedBot.id,
        this.selectedBot.key,
        this.selectedBot.password,
        message,
        dialog,
        agentsSchedule,
        livecardSchedule,
        timezone,
        scheduledChatClosingDialog,
        scheduledChatClosingDialogType,
        scheduledChatClosingDialogAt,
        maxChatsAssigned,
        chatAssignmentType,
        autoAssignChatUserTypes,
        queuePositionEnabled,
        queuePositionDialog,
        enableAutoAssignRequestOnNextDay,
        chatTransferredTimeout,
        autoAssignDealUserTypes,
        autoAssignRoundRobinChat,
        roundRobinChatTimeout,
        disableACMForChatTransfer,
        disableCCDForChatTransfer,
        backupLivecardId,
        backupLivecardTimeout
      );

      this.setSelectedBot({
        ...this.selectedBot,
        settings: {
          agentsOutOfScheduleMessage: message,
          agentsOutOfScheduleDialog: dialog,
          agentsSchedule,
          livecardSchedule,
          usersAllowedToAssignChats,
          timezone,
          scheduledChatClosingDialog,
          scheduledChatClosingDialogType,
          scheduledChatClosingDialogAt,
          maxCallsAssigned,
          maxChatsAssigned,
          chatAssignmentType,
          autoAssignChatUserTypes,
          queuePositionEnabled,
          queuePositionDialog,
          enableAutoAssignRequestOnNextDay,
          chatTransferredTimeout,
          autoAssignDealUserTypes,
          autoOpenChat360ModalWhenAgentConnects,
          autoAssignRoundRobinChat,
          roundRobinChatTimeout,
          disableACMForChatTransfer,
          disableCCDForChatTransfer,
          disableChatHistory,
          guestModeEnabled,
          dealUpdateRequired,
          backupLivecardId,
          backupLivecardTimeout,
        },
      });

      await this.db.collection("bots").doc(`${this.selectedBot.id}`).set(
        {
          settings: {
            usersAllowedToAssignChats,
            maxCallsAssigned,
            maxChatsAssigned,
            enableAutomaticChatAcceptance,
            autoOpenChat360ModalWhenAgentConnects,
            disableChatHistory,
            guestModeEnabled,
            dealUpdateRequired,
          },
        },
        { merge: true }
      );

      audit.logEvent("bot_settings_updated", {
        doc: {
          agentsOutOfScheduleMessage: message,
          agentsOutOfScheduleDialog: dialog,
          agentsSchedule,
          livecardSchedule,
          usersAllowedToAssignChats,
          timezone,
          scheduledChatClosingDialog,
          scheduledChatClosingDialogAt,
          maxCallsAssigned,
          maxChatsAssigned,
          enableAutomaticChatAcceptance,
          chatTransferredTimeout,
          autoAssignDealUserTypes,
          autoOpenChat360ModalWhenAgentConnects,
          autoAssignRoundRobinChat,
          roundRobinChatTimeout,
          guestModeEnabled,
          dealUpdateRequired,
        },
        botId: this.selectedBot.id,
      });
    } catch (error) {
      logError(error);
    }
  };

  setEnableEndaBotInSettings = (enabled: boolean) => {
    if (!this.selectedBot) {
      return;
    }

    this.setSelectedBot({
      ...this.selectedBot,
      settings: {
        ...this.selectedBot.settings,
        ednaBotEnabled: enabled,
      },
    });
  };

  updateWhatsappProviderInBot = (whatsappProvider?: "twilio" | "meta") => {
    if (!this.selectedBot) {
      return;
    }

    this.setSelectedBot({
      ...this.selectedBot,
      settings: {
        ...this.selectedBot.settings,
        whatsappProvider: whatsappProvider,
      },
    });
  };

  updateUserLastActivity = () => {
    if (!auth?.user || !this.selectedBot) {
      return;
    }

    this.db.collection("users").doc(auth.user.id).update({
      lastActivity: firebase.firestore.FieldValue.serverTimestamp(),
      lastActivityOnBot: this.selectedBot.id,
    });

    sendEvents(this.selectedBot.id, auth.user.id, "new_session");
  };

  private setSelectedBot = (bot?: Bot) => {
    this.selectedBot = bot;

    if (bot) {
      if (!this.lastInterval) {
        this.lastInterval = setInterval(this.updateUserLastActivity, 10800000); // 3 hours
      }
    } else {
      if (this.lastInterval) {
        clearInterval(this.lastInterval);
        this.lastInterval = undefined;
      }
    }

    this.updateUserLastActivity();
  };

  updateBotBalance = (balance: number) => {
    if (this.selectedBot?.balance) {
      this.selectedBot.balance += balance;
    }
  };

  updateBotCustomization = async (customization: BotCustomization) => {
    if (!this.selectedBot) {
      return;
    }

    await this.db.collection("bots").doc(`${this.selectedBot.id}`).set(
      {
        customization,
      },
      { merge: true }
    );

    audit.logEvent("bot_customization_updated", {
      doc: customization,
      botId: this.selectedBot.id,
    });
  };

  updateBotCustomPlan = async (customPlanValues: Partial<CustomPlanValues>) => {
    if (!this.selectedBot) {
      return;
    }

    await this.db.collection("bots").doc(`${this.selectedBot.id}`).set(
      {
        customPlanValues,
      },
      { merge: true }
    );

    audit.logEvent("bot_custom_plan_updated", {
      doc: customPlanValues,
      botId: this.selectedBot.id,
    });
  };

  updateBotPlan = async (plan: Partial<BotPlan>) => {
    if (!this.selectedBot) {
      return;
    }

    await this.db.collection("bots").doc(`${this.selectedBot.id}`).set(
      {
        plan,
      },
      { merge: true }
    );

    audit.logEvent("bot_plan_updated", {
      doc: plan,
      botId: this.selectedBot.id,
    });
  };

  // initSubscription = async (bot: Bot) => {

  //   const subscriptionSnap = await this.db
  //     .collection("subscriptions")
  //     .doc(`${bot.id}`)
  //     .get();

  //   this.subscriptionLoaded = false;

  //   this.subscriptionLoaded = true;

  //   this.subscription = subscriptionSnap.data() as Subscription;
  // }

  isModuleActive = (featureName: AppModule) => {
    if (featureName === "BILLING") return false;

    return (
      this.selectedBot?.subscription?.status !== SubscriptionStatus.Active ||
      !this.checkFeatureOrAddon(featureName)
    );
  };

  checkFeatureOrAddon = (name: string) => {
    const featureIndex = features.findIndex((m) => m.key === name);

    if (featureIndex === -1) return true;

    const feature = features[featureIndex] as Feature;

    if (feature.type === "default") {
      return this.planHasFeature(name);
    } else {
      return this.isAddonActive(name);
    }
  };

  isAddonActive = (key: string) => {
    return true;
    // return (
    //   (this.selectedBot?.subscription?.plan?.addons?.findIndex(
    //     (a) => a.key === key
    //   ) || 0) > -1
    // );
  };

  planHasFeature = (featureName: string) => {
    return true;
    // const featureIndex = features.findIndex((m) => m.key === featureName);

    // if (featureIndex === -1) return true;

    // return (
    //   (features[featureIndex]?.includedIn?.indexOf(
    //     this.selectedBot?.subscription?.plan?.key || ""
    //   ) || 0) > -1
    // );
  };

  addLiveUsage = async (noOfSec: number, addonKey: string) => {
    return;
    // if (!this.selectedBot?.id) {
    //   return;
    // }

    // const addon = this.selectedBot?.subscription?.plan?.addons?.find(
    //   (p) => p.key === addonKey
    // );

    // if (!addon) {
    //   return;
    // }

    // try {
    //   const price = parseFloat(
    //     ((noOfSec / 60) * (addon.data.price || 0)).toFixed(2)
    //   );
    //   let LiveUsage: LiveUsage = {
    //     botId: this.selectedBot.id,
    //     addonKey,
    //     noOfSec,
    //     price,
    //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //   };

    //   await this.db
    //     .collection("bots")
    //     .doc(`${this.selectedBot.id}`)
    //     .collection("LiveUsages")
    //     .add(LiveUsage);

    //   await this.db
    //     .collection("bots")
    //     .doc(`${this.selectedBot.id}`)
    //     .set(
    //       {
    //         balance: firebase.firestore.FieldValue.increment(-price),
    //       },
    //       { merge: true }
    //     );
    // } catch (e) {
    //   console.error(e);
    // }
  };

  isLowBalance = () => {
    return false;
    // return (this.selectedBot?.balance || 0) <= 0;
  };

  findAgentsByGroup = async (groupId: number) => {
    if (!this.selectedBot) return;
    const snap = await this.db
      .collection("users")
      .where("botsIds", "array-contains", this.selectedBot.id)
      .get();

    let users = snap.docs
      .map((m) => m.data() as User)
      .filter((user) => {
        const liveChatFilters =
          auth.getPermissions(user)?.liveChatFilters || {};
        const activeGroups = Object.keys(liveChatFilters).filter(
          (key) => liveChatFilters[key].length > 0
        );
        return activeGroups?.includes(String(groupId)) || false;
      });

    return users?.map((u) => u.id) || [];
  };

  loadBotSettings = async () => {
    if (!this.selectedBot) {
      return;
    }

    const settings = await getBotSettings(
      this.selectedBot.id,
      this.selectedBot.key,
      this.selectedBot.password
    );

    if (settings) {
      this.botSettings = settings;
      this.botSettingsKey = this.botSettingsKey + 1;
    }
    this.botSettingsLoaded = true;
  };

  updateBotSetting = async (type: string, values: any) => {
    if (!this.selectedBot) return null;

    try {
      await postBotSettings(
        this.selectedBot.id,
        this.selectedBot.key,
        this.selectedBot.password,
        type,
        {
          ...values,
          workspaceName: workspaces?.selectedWorkspace?.companyName,
          adminEmail: auth.user?.email,
        }
      );

      this.botSettings = {
        ...this.botSettings,
        ...values,
      };

      if (type === "whatsapp") {
        this.updateWhatsappProviderInBot(values.whatsappProvider);
      }

      let name = type;
      name = name.charAt(0).toUpperCase() + name.slice(1);

      toast({
        message: t(locale.setupUpdatedSuccessfully, { name }),
        position: "top-right",
        dismissible: true,
        type: "is-success",
        pauseOnHover: true,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          message: error.message,
          position: "top-right",
          dismissible: true,
          type: "is-danger",
          pauseOnHover: true,
        });
      }
      console.log(error);
    }
  };

  routeTo = (route: string) => {
    if (!workspaces.selectedWorkspace || !workspaces.selectedFunction) {
      return "";
    }

    return route
      .replace(":workspaceId", workspaces.selectedWorkspace.id)
      .replace(":functionId", workspaces.selectedFunction.slug);
  };

  loadTemplates = async (forMetaWhatsapp?: boolean) => {
    if (!this.selectedBot) return;
    this.setTemplates([]);

    let query = firebase
      .firestore()
      .collection("bots")
      .doc(String(this.selectedBot.id))
      .collection("whatsappTemplates")
      .orderBy("createdAt", "desc");

    const snapshot = await query.get();

    const data = snapshot.docs
      .map((doc) => {
        const obj = doc.data();
        obj.id = doc.id;
        return obj;
      })
      .filter((f: any) => !f.deletedAt)
      .filter((f: any) => {
        if (forMetaWhatsapp) {
          return f.metaWhatsapp === true;
        }
        return !f.metaWhatsapp;
      }) as WhatsappTemplate[];

    this.setTemplates(data);
  };

  private setCannedResponses = (cannedResponses: CannedResponse[]) => {
    this.cannedResponses = cannedResponses;
  };

  private setUsersInCannedResponses = (usersInCannedResponses: User[]) => {
    this.usersInCannedResponses = usersInCannedResponses;
  };

  private setLabels = (labels: Label[]) => {
    this.labels = labels;
  };

  private setTemplates = (templates: WhatsappTemplate[]) => {
    this.templates = templates;
  };
}

export default new BotsStore();
