export enum DeliveryStatus {
  Accepted = "accepted",
  Queued = "queued",
  Sending = "sending",
  Sent = "sent",
  Failed = "failed",
  Delivered = "delivered",
  Undelivered = "undelivered",
  Receiving = "receiving",
  Received = "received",
  Read = "read",
}

export type Attachment = {
  type: string;
  payload?: any;
};

export enum MetaType {
  Text = 1,
  TriggeredText = 2,
  LiveChatRequested = 3,
  LiveChatConnected = 4,
  LiveChatDisconnected = 5,
  Fallback = 6,
  TriggeredMultimedia = 7,
  VideoCall = 8,
  Transfer = 9, // Conflict with Audio
  ClosingScheduleMessage = 10, // Conflict with Video
  Image = 11,
  File = 12,
  Template = 13,
  // ...
  ActivityLog = 50,
  InactiveMessage = 51,
  InactiveChatClosed = 52,
}

export type Message = {
  id: number;
  text?: string;
  attachment?: Attachment;
  attachments?: Attachment[];
  senderId?: string;
  deliveryStatus?: DeliveryStatus;
  errorCode?: string;
  errorMessage?: string;
  isFromUser: boolean;
  isDeleted: boolean;
  firstName?: string;
  lastName?: string;
  metaType?: MetaType;
  createdAt: Date;
  confidential?: boolean;
  meta?: Record<string, any>;
};
