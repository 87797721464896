import locale from "../constants/locale";
import { Bot } from "./Bot";
import { ChatChannel } from "./Chat";
import firebaseApp from "firebase/compat/app";

export enum UserType {
  Root = -1,
  Administrator = 0,
  Supervisor = 1,
  Collaborator = 2,
}

export enum SupportedLanguage {
  en = "en",
  es = "es",
}

export const supportedLanguageMap: Record<SupportedLanguage, string> = {
  [SupportedLanguage.en]: "English",
  [SupportedLanguage.es]: "Spanish",
};

export type UserSettings = {
  disableVideoCall: boolean;
};

export type LiveChatFilters = {
  [id: string]: ChatChannel[];
};

export type AppModule =
  | "CHATS"
  | "QUICK_REPLIES"
  | "CONTACTS"
  | "LIVE_STREAM"
  | "CAMPAIGNS"
  | "CAMPAIGN_WA_NEW_MESSAGE_TEMPLATE"
  | "BOARDS"
  | "BILLING"
  | "ANALYTICS"
  | "USERS"
  | "SETTINGS"
  | "HELP_CENTER"
  | "CATALOGS"
  | "SETUP"
  | "TEAMMATES"
  | "ORGANIZATION_KNOWLEDGE"
  | "AI_TEAMS"
  | "AI_TEAMS_CHAT"
  | "ERROR_LOGS"
  | "COMMERCE"
  | "EMAIL_TEMPLATES"
  | "WORKFLOWS"
  | "WORKSPACE_SETTINGS";

export type PermissionsPerFunction = {
  liveChatFilters?: LiveChatFilters;
  restrictedModules?: AppModule[];
  restrictLabelCreation?: boolean;
  assignedCard?: boolean;
  canViewAllContacts?: boolean;
  viewOnlyAccess?: boolean;
  canCreateOrDeleteDeals?: boolean;
  isFunctionActive?: boolean;
  chatCounts?: number;
  roundRobinPointer?: boolean;
  canCreateChatRooms?: boolean;
  canViewAllChats?: boolean;
  canSendMessageFromInbox?: boolean;
  canDisconnectAllChats?: boolean;
  canSkipClosingDialog?: boolean;
  restrictAllAnalytics?: boolean;
};

export type PermissionsPerBot = {
  [id: string]: {
    liveChatFilters?: LiveChatFilters;
    restrictedModules?: AppModule[];
    assignedCard?: boolean;
    canViewAllContacts?: boolean;
    userDeactivated?: boolean;
    viewOnlyAccess?: boolean;
  };
};

export type PermissionsPerWorkspace = {
  [workspaceId: string]: {
    userDeactivated?: boolean;
    disableProductTour?: boolean;
    defaultChatFilter?: string;
    perFunction?: {
      [functionId: string]: PermissionsPerFunction;
    };
  };
};

export type UserPermissions = {
  perWorkspace?: PermissionsPerWorkspace;
  perBot?: PermissionsPerBot;
};

export enum UserStatus {
  ONLINE = "online",
  AWAY = "away",
  OFFLINE = "offline",
}

export const userStatusMap: Record<UserStatus, string> = {
  [UserStatus.ONLINE]: "Online",
  [UserStatus.AWAY]: "Away",
  [UserStatus.OFFLINE]: "Offline",
};

export const userStatusColorMap: Record<UserStatus, string> = {
  [UserStatus.ONLINE]: "#27AE60",
  [UserStatus.AWAY]: "yellow",
  [UserStatus.OFFLINE]: "#b5b5b5",
};

export type User = {
  id: string;
  email: string;
  type: UserType;
  isDisabled: boolean;
  preferredLanguage: SupportedLanguage;
  phoneNumber?: string;
  name?: string;
  image?: string;
  info?: any;
  bots?: Pick<Bot, "id" | "image" | "key" | "password" | "name">[];
  botsIds?: number[];
  workspaceIds?: string[];
  settings?: UserSettings;
  permissions?: UserPermissions;
  createdAt: Date;
  updatedAt: Date;
  lastActivity?: Date;
  lastActivityOnBot?: number;
  enrolled2fa?: boolean;
  skip2fa?: boolean;
  status?: UserStatus;
  statusLogs?: UserStatusLog[];
  avatarUrl?: string;
  ednaChromeToken?: string;
  affiliateId?: string;
};

export type UserStatusLog = {
  oldStatus: UserStatus | undefined;
  status: UserStatus;
  timeSpent?: number;
  createdAt: Date | firebaseApp.firestore.FieldValue;
};

export type UserAssignedChat = {
  current_agent: string;
  ongoingChats?: number;
  ongoingCalls?: number;
};

export const userTypeMap = {
  [UserType.Root]: locale.rootUser,
  [UserType.Administrator]: locale.administratorUser,
  [UserType.Supervisor]: locale.supervisorUser,
  [UserType.Collaborator]: locale.collaboratorUser,
};

export const appModuleMap: Record<AppModule, string> = {
  CHATS: locale.chats,
  QUICK_REPLIES: locale.cannedResponses,
  CONTACTS: locale.contacts,
  LIVE_STREAM: locale.liveStream,
  CAMPAIGNS: locale.campaigns,
  BOARDS: locale.board,
  BILLING: locale.billing,
  ANALYTICS: locale.analytics,
  USERS: locale.users,
  SETTINGS: locale.settings,
  HELP_CENTER: locale.helpCenter,
  CATALOGS: locale.catalogs,
  CAMPAIGN_WA_NEW_MESSAGE_TEMPLATE: "Create new message template",
  SETUP: locale.gettingStarted,
  TEAMMATES: locale.teammates,
  ORGANIZATION_KNOWLEDGE: locale.orgKnowledge,
  AI_TEAMS: locale.aITeams,
  AI_TEAMS_CHAT: locale.aITeamsChat,
  ERROR_LOGS: locale.errorLogs,
  COMMERCE: locale.commerce,
  EMAIL_TEMPLATES: locale.emailTemplates,
  WORKFLOWS: locale.workflows,
  WORKSPACE_SETTINGS: locale.settings,
};
