import { makeAutoObservable } from "mobx";
import firebase from "firebase/compat/app";
import workspaces from "./workspaces";
import { parseFirebaseDateTime } from "../utils/internationalization";
import {
  Booking,
  Ticket,
  TicketStatus,
  TicketType,
  typeTypeColorMap,
} from "../models";
import { parseFirebaseInFilterValue } from "../utils/parseFirebaseFilterValue";

export type CalendarEvent = {
  id: string;
  title: string;
  type: "ticket" | "event";
  start: string;
  end?: string;
  allDay?: boolean;
  resource?: any;
  meta?: any;
};

class CalendarStore {
  private db: firebase.firestore.Firestore;
  events: CalendarEvent[] = [];

  constructor() {
    makeAutoObservable(this);
    this.db = firebase.firestore();
  }

  loadEvents = async (group?: number) => {
    if (!workspaces.selectedWorkspace) return;

    const bookings = await this.getBookings(group);
    const tickets = await this.getTickets(group);
    const events = [...bookings, ...tickets];
    this.setEvents(events);
  };

  getBookings = async (group?: number) => {
    if (!workspaces.selectedWorkspace) return [];

    const bookingSnapshot = await this.db
      .collection("workspaces")
      .doc(workspaces.selectedWorkspace.id)
      .collection("bookings")
      .where(
        "event.livecardId",
        "in",
        group
          ? [group]
          : parseFirebaseInFilterValue(
              workspaces.activeLiveChatCards.map((c) => c.id)
            )
      )
      .get();

    return bookingSnapshot.docs
      .map(
        (doc) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Booking)
      )
      .filter((f) => !f.cancelledAt)
      .map((booking) => ({
        id: booking.id,
        title: booking.user?.name ?? booking.id,
        type: "event",
        start: booking.start as any,
        end: booking.end as any,
        color: booking.event.color,
        meta: booking,
      })) as CalendarEvent[];
  };

  getTickets = async (group?: number) => {
    if (!workspaces.selectedWorkspace) return [];

    const selectedFunction = workspaces.selectedWorkspace?.functions?.find(
      (f) => f.slug === "op"
    );
    if (selectedFunction && selectedFunction?.meta?.bot?.id) {
      const snapshot = await firebase
        .firestore()
        .collection("bots")
        .doc(String(selectedFunction?.meta?.bot?.id))
        .collection("tasks")
        .where(
          "liveCardId",
          "in",
          group
            ? [group]
            : parseFirebaseInFilterValue(
                workspaces.activeLiveChatCards.map((c) => c.id)
              )
        )
        .orderBy("createdAt", "desc")
        .get();

      return snapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as Ticket)
        )
        .filter((ticket) => ticket.status !== TicketStatus.CLOSED)
        .map(
          (ticket) =>
            ({
              id: ticket.id,
              type: "ticket",
              title: ticket.name,
              start: parseFirebaseDateTime(ticket.dueDate, null, true),
              allDay: true,
              color: typeTypeColorMap[(ticket.type as TicketType) ?? "task"],
              meta: ticket,
            } as CalendarEvent)
        );
    }
    return [];
  };

  markTicketAsDone = async (ticket: Ticket) => {
    this.events = this.events.map((event) => {
      if (event.id === ticket.id) {
        return {
          ...event,
          meta: {
            ...event.meta,
            status: TicketStatus.DONE,
          },
        };
      }
      return event;
    });
  };

  updateBooking = (booking: Booking) => {
    this.setEvents(
      this.events
        .map((f) => {
          if (f.id === booking.id && f.type === "event") {
            return {
              ...f,
              start: booking.start as any,
              meta: booking,
            };
          }
          return f;
        })
        .filter((f) => {
          if (
            f.id === booking.id &&
            f.type === "event" &&
            f.meta?.cancelledAt
          ) {
            return false;
          }
          return true;
        })
    );
  };

  private setEvents = (events: CalendarEvent[]) => {
    this.events = events;
  };
}

export default new CalendarStore();
