import styles from "../WorkspaceSidebar.module.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores";
import { useCallback, useMemo } from "react";
import locale from "../../../constants/locale";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import routes from "../../../constants/routes";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

const AnalyticsLink = observer(() => {
  const { selectedWorkspace } = useStore("workspaces");
  const { userHasAdminAccess, user } = useStore("auth");
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const routeTo = useCallback(
    (path: string) => {
      history.push(
        path
          .replace(":workspaceId", String(selectedWorkspace?.id))
          .replace(":functionId", "op")
      );
    },
    [history, selectedWorkspace]
  );

  const isActiveRoute = location.pathname.startsWith(
    routes.allAnalytics
      .replace(":workspaceId", String(selectedWorkspace?.id))
      .replace(":functionId", "op")
  );

  const canView = useMemo(() => {
    if (!selectedWorkspace?.id) {
      return false;
    }
    if (userHasAdminAccess) {
      return true;
    }
    if (
      user?.permissions?.perWorkspace?.[selectedWorkspace.id]?.perFunction?.op
        ?.restrictAllAnalytics
    ) {
      return false;
    }
    return true;
  }, [selectedWorkspace?.id, user?.permissions, userHasAdminAccess]);

  if (!selectedWorkspace || !canView) {
    return null;
  }

  return (
    <button
      onClick={() => routeTo(routes.allAnalytics)}
      style={{ height: "5rem" }}
      className="is-relative w-100 is-no-box-shadow button has-text-white is-flex is-flex-direction-column is-ghost"
    >
      <div
        style={{ display: "flex", padding: "0.4rem" }}
        className={"is-relative"}
      >
        {isActiveRoute && <div className={styles.active}></div>}
        <TbDeviceDesktopAnalytics
          style={{ zIndex: 50, padding: 2 }}
          color={isActiveRoute ? "#2A79EF" : undefined}
          size={26}
        />
      </div>
      <span className="subtitle is-7 has-text-white has-long-text">
        {t(locale.analytics)}
      </span>
    </button>
  );
});

export default AnalyticsLink;
