const locale = {
  errorLoadingPage: "errorLoadingPage",
  signIn: "signIn",
  signUp: "signUp",
  genericError: "genericError",
  invalidEmail: "invalidEmail",
  fieldRequired: "fieldRequired",
  passwordMinLength: "passwordMinLength",
  wrongPassword: "wrongPassword",
  userDisabled: "userDisabled",
  userNotFound: "userNotFound",
  email: "email",
  password: "password",
  name: "name",
  or: "or",
  signInWith: "signInWith",
  signInWelcomeBack: "signInWelcomeBack",
  signInWelcomeBackEnterYourDetails: "signInWelcomeBackEnterYourDetails",
  signInDontHaveAnAccount: "signInDontHaveAnAccount",
  signUpWith: "signUpWith",
  signUpAlreadyHaveAnAccount: "signUpAlreadyHaveAnAccount",
  acceptTerms: "acceptTerms",
  signUpToWelcome: "signUpToWelcome",
  signUpToBegin: "signUpToBegin",
  letsLinkYourBot: "letsLinkYourBot",
  botId: "botId",
  key: "key",
  linkBot: "linkBot",
  canNotLinkBot: "canNotLinkBot",
  linkAccounts: "linkAccounts",
  confirm: "confirm",
  connect: "connect",
  disconnect: "disconnect",
  send: "send",
  details: "details",
  save: "save",
  firstName: "firstName",
  lastName: "lastName",
  writeMessage: "writeMessage",
  notes: "notes",
  writeNote: "writeNote",
  add: "add",
  chats: "chats",
  logOut: "logOut",
  cannedResponses: "cannedResponses",
  addCanResponses: "addCanResponses",
  contacts: "contacts",
  addContact: "addContact",
  writeCannedResponse: "writeCannedResponse",
  labels: "labels",
  addLabel: "addLabel",
  open: "open",
  done: "done",
  markAsOpen: "markAsOpen",
  markAsDone: "markAsDone",
  sessionTimeout: "sessionTimeout",
  stillHere: "stillHere",
  youWillBeLoggedOutDescription: "youWillBeLoggedOutDescription",
  day: "day",
  from: "from",
  to: "to",
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday",
  agentsOutOfScheduleMessage: "agentsOutOfScheduleMessage",
  agentsSchedule: "agentsSchedule",
  settings: "settings",
  requested: "requested",
  connected: "connected",
  all: "all",
  delete: "delete",
  askDeleteConfirmation: "askDeleteConfirmation",
  cancel: "cancel",
  cannedResponsesDescription: "cannedResponsesDescription",
  schedule: "schedule",
  scheduleDescription: "scheduleDescription",
  noRequestsChats: "noRequestsChats",
  noConnectedChats: "noConnectedChats",
  noAllChats: "noAllChats",
  askSettingsSaveConfirmation: "askSettingsSaveConfirmation",
  searchChats: "searchChats",
  timezone: "timezone",
  addBot: "addBot",
  botAlreadyAdded: "botAlreadyAdded",
  fileIsTooLarge: "fileIsTooLarge",
  fileHasNoSupportedFormat: "fileHasNoSupportedFormat",
  myQueue: "myQueue",
  onlineAgents: "onlineAgents",
  chatAssignedToYou: "chatAssignedToYou",
  accept: "accept",
  reject: "reject",
  usersAllowedToAssignChats: "usersAllowedToAssignChats",
  scheduledChatClosingDialog: "scheduledChatClosingDialog",
  scheduledChatClosingDialogAt: "scheduledChatClosingDialogAt",
  rootUser: "rootUser",
  administratorUser: "administratorUser",
  supervisorUser: "supervisorUser",
  collaboratorUser: "collaboratorUser",
  agentConnected: "agentConnected",
  anAgentIsAlreadyConnected: "anAgentIsAlreadyConnected",
  calling: "calling",
  rejected: "rejected",
  timedOut: "timedOut",
  canceled: "canceled",
  ongoing: "ongoing",
  callEnded: "callEnded",
  leavingEndsCall: "leavingEndsCall",
  disableVideoCall: "disableVideoCall",
  users: "users",
  userEdit: "userEdit",
  userType: "userType",
  search: "search",
  analytics: "analytics",
  dateFilter: "dateFilter",
  addMetric: "addMetric",
  channel: "channel",
  metricAlreadyAdded: "metricAlreadyAdded",
  allChannels: "allChannels",
  liveStream: "liveStream",
  noCurrentLiveStream: "noCurrentLiveStream",
  startLiveStream: "startLiveStream",
  setUpLiveStream: "setUpLiveStream",
  streamNameKey: "streamNameKey",
  streamLocationUrl: "streamLocationUrl",
  stopLiveStream: "stopLiveStream",
  noPastLiveChats: "noPastLiveChats",
  groups: "groups",
  scheduleHelperText: "scheduleHelperText",
  selectLiveStreamGroup: "selectLiveStreamGroup",
  resourceNotFound: "resourceNotFound",
  messageDeleted: "messageDeleted",
  story: "story",
  storyDeletedOrExpired: "storyDeletedOrExpired",
  newMessageReceived: "newMessageReceived",
  showRequestedChatsNotifications: "showRequestedChatsNotifications",
  showMyChatsNotifications: "showMyChatsNotifications",
  campaigns: "campaigns",
  pastCampaigns: "pastCampaigns",
  createCampaignDescription: "createCampaignDescription",
  createCampaign: "createCampaign",
  uploadAudience: "uploadAudience",
  launchCampaign: "launchCampaign",
  startCampaign: "startCampaign",
  custom: "custom",
  manualUpload: "manualUpload",
  campaignName: "campaignName",
  campaignData: "campaignData",
  file: "file",
  noPastCampaigns: "noPastCampaigns",
  template: "template",
  allDay: "allDay",
  closed: "closed",
  closeAll: "closeAll",
  addAsContact: "addAsContact",
  removeFromContacts: "removeFromContacts",
  createdAt: "createdAt",
  lastMessage: "lastMessage",
  contact: "contact",
  phoneNumber: "phoneNumber",
  addField: "addField",
  typeNameField: "typeNameField",
  board: "board",
  createBoard: "createBoard",
  create: "create",
  addFirstColumn: "addFirstColumn",
  addFirstColumnDesc: "addFirstColumnDesc",
  column: "column",
  updateColumn: "updateColumn",
  askForColumnName: "askForColumnName",
  sendToBoard: "sendToBoard",
  removeFromBoard: "removeFromBoard",
  table: "table",
  tableHeader: "tableHeader",
  bulkUpload: "bulkUpload",
  manualInput: "manualInput",
  recipients: "recipients",
  message: "message",
  history: "history",
  noLogs: "noLogs",
  changedFields: "changedFields",
  changedPosition: "changedPosition",
  movedToBoard: "movedToBoard",
  movedToColumn: "movedToColumn",
  removedFromBoard: "removedFromBoard",
  addedAsContact: "addedAsContact",
  removedAsContact: "removedAsContact",
  empty: "empty",
  previous: "previous",
  next: "next",
  pagination: "pagination",
  goToPage: "goToPage",
  invalidInput: "invalidInput",
  pageSize: "pageSize",
  contactsMerged: "contactsMerged",
  noContactsMerged: "noContactsMerged",
  mergedContact: "mergedContact",
  unmergedContact: "unmergedContact",
  access: "access",
  noAccessToThisResource: "noAccessToThisResource",
  assignee: "assignee",
  none: "none",
  label: "label",
  liveChatConnected: "liveChatConnected",
  liveChatDisconnected: "liveChatDisconnected",
  hours: "hours",
  immediately: "immediately",
  showCard: "showCard",
  contactAlreadyExists: "contactAlreadyExists",
  botTransferred: "botTransferred",
  maxChatsAssigned: "maxChatsAssigned",
  maxCallsAssigned: "maxCallsAssigned",
  noCollaboratorsAvailable: "noCollaboratorsAvailable",
  agentsOutOfScheduleDialog: "agentsOutOfScheduleDialog",
  agentsOutOfScheduleDialogTooltip: "agentsOutOfScheduleDialogTooltip",
  ofLast7Days: "ofLast7Days",
  mostActiveSchedule: "mostActiveSchedule",
  conversationsOverTime: "conversationsOverTime",
  todayTraffic: "todayTraffic",
  chatsConnected: "chatsConnected",
  chatsDisconnected: "chatsDisconnected",
  channels: "channels",
  total: "total",
  collaborator: "collaborator",
  messagesSent: "messagesSent",
  meanTimeToConnect: "meanTimeToConnect",
  averageHandlingTime: "averageHandlingTime",
  teamPerformance: "teamPerformance",
  countContacts: "countContacts",
  showRequestedChats: "showRequestedChats",
  blocked: "blocked",
  blockUser: "blockUser",
  unblockUser: "unblockUser",
  blockedUser: "blockedUser",
  unblockedUser: "unblockedUser",
  topDialogNames: "topDialogNames",
  billing: "billing",
  addCard: "addCard",
  addCardDescription: "addCardDescription",
  card: "card",
  cardEndingIn: "cardEndingIn",
  expiry: "expiry",
  month: "month",
  currentPlan: "currentPlan",
  choose: "choose",
  chatBotWithAi: "chatBotWithAi",
  upToHumanAgents: "upToHumanAgents",
  includesWaMessages: "includesWaMessages",
  waNumberConfig: "waNumberConfig",
  allOFPlus: "allOFPlus",
  apiAccess: "apiAccess",
  dedicatedAccountManager: "dedicatedAccountManager",
  customizePlatform: "customizePlatform",
  customApiDevelopment: "customApiDevelopment",
  unlimitedHumanAgents: "unlimitedHumanAgents",
  availablePlans: "availablePlans",
  extraAgents: "extraAgents",
  extraAgentsTooltip: "extraAgentsTooltip",
  planDetails: "planDetails",
  price: "price",
  agentsIncluded: "agentsIncluded",
  agentPrice: "agentPrice",
  tieredPrices: "tieredPrices",
  tieredPricesTooltip: "tieredPricesTooltip",
  moreThan: "moreThan",
  upgradePlanToUseThisResource: "upgradePlanToUseThisResource",
  botHasAgentsLimit: "botHasAgentsLimit",
  edit: "edit",
  editCard: "editCard",
  addCardSetUp: "addCardSetUp",
  addCardSetUpDescription: "addCardSetUpDescription",
  viewSource: "viewSource",
  letsAdd2fa: "letsAdd2fa",
  verifyItsYou: "verifyItsYou",
  sendSMS: "sendSMS",
  weWillSendSms: "weWillSendSms",
  registerNumber: "registerNumber",
  verificationCode: "verificationCode",
  invalidVerificationCode: "invalidVerificationCode",
  reauthenticate: "reauthenticate",
  reauthenticateText: "reauthenticateText",
  continue: "continue",
  verifyEmail: "verifyEmail",
  ok: "ok",
  verifyYourEmail: "verifyYourEmail",
  phoneNumberUsedInAnotherAccount: "phoneNumberUsedInAnotherAccount",
  resetPassword: "resetPassword",
  typeNewPassword: "typeNewPassword",
  unexpectedError: "unexpectedError",
  recoverEmail: "recoverEmail",
  recoveredEmail: "recoveredEmail",
  emailVerification: "emailVerification",
  emailVerified: "emailVerified",
  selected: "selected",
  select: "select",
  updateProfileInfo: "updateProfileInfo",
  profileUpdate: "profileUpdate",
  allLabels: "allLabels",
  noBoard: "noBoard",
  logout: "logout",
  deleteBoard: "deleteBoard",
  changeName: "changeName",
  description: "description",
  date: "date",
  media: "media",
  action: "action",
  spam: "spam",
  staySignedIn: "staySignedIn",
  permissions: "permissions",
  showOnlyAssignedCards: "showOnlyAssignedCards",
  billingOverview: "billingOverview",
  billingPaymentHistory: "billingPaymentHistory",
  billingRecurringItems: "billingRecurringItems",
  billingPaymentSettings: "billingPaymentSettings",
  billingPrefrences: "billingPrefrences",
  currentBalance: "currentBalance",
  addFunds: "addFunds",
  autoRecharge: "autoRecharge",
  changePlan: "changePlan",
  inActive: "inActive",
  paymentMethod: "paymentMethod",
  amount: "amount",
  paymentHistory: "paymentHistory",
  addon: "addon",
  usageSummary: "usageSummary",
  balance: "balance",
  paymentAmount: "paymentAmount",
  newBalance: "newBalance",
  addFundsDescription: "addFundsDescription",
  addFundsMinMaxAmount: "addFundsMinMaxAmount",
  selectPaymentMethod: "selectPaymentMethod",
  addNewPaymentMethod: "addNewPaymentMethod",
  addToMyBalance: "addToMyBalance",
  overview: "overview",
  type: "type",
  status: "status",
  totalThisMonth: "totalThisMonth",
  allBillingDateAndTimesAreInUTC: "allBillingDateAndTimesAreInUTC",
  paymentSetting: "paymentSetting",
  expiryDate: "expiryDate",
  number: "number",
  default: "default",
  makeDefault: "makeDefault",
  cardHolderName: "cardHolderName",
  billingAddress: "billingAddress",
  country: "country",
  address1: "address1",
  address2: "address2",
  city: "city",
  state: "state",
  zip: "zip",
  autoRechargeDescription: "autoRechargeDescription",
  whenBalanceGoesDownBelow: "whenBalanceGoesDownBelow",
  bringMyBalanceBackUpTo: "bringMyBalanceBackUpTo",
  addFundsForExample: "addFundsForExample",
  usingThisPrimaryMethod: "usingThisPrimaryMethod",
  onlyPaymentMethodAvailableShowHere: "onlyPaymentMethodAvailableShowHere",
  enableAutoRecharge: "enableAutoRecharge",
  company: "company",
  billingPrefrencesDescription: "billingPrefrencesDescription",
  generalDetails: "generalDetails",
  companyName: "companyName",
  poNumber: "poNumber",
  serviceAddress: "serviceAddress",
  location: "location",
  attentionTo: "attentionTo",
  streetAddress: "streetAddress",
  address: "address",
  street: "street",
  province: "province",
  postalCode: "postalCode",
  businessTaxID: "businessTaxID",
  iHaveABusinessTaxID: "iHaveABusinessTaxID",
  title: "title",
  invoiceList: "invoiceList",
  plans: "plans",
  addons: "addons",
  addonsDescription: "addonsDescription",
  estimatedCost: "estimatedCost",
  update: "update",
  subscribe: "subscribe",
  subscribed: "subscribed",
  pro: "pro",
  starter: "starter",
  premium: "premium",
  item: "item",
  downgrade: "downgrade",
  downgradeConfirmation: "downgradeConfirmation",
  downgradeText1: "downgradeText1",
  downgradeText2: "downgradeText2",
  workspaceInvitation: "workspaceInvitation",
  clickToJoin: "clickToJoin",
  addUser: "addUser",
  clickToGenerateURL: "clickToGenerateURL",
  copyURL: "copyURL",
  urlCopied: "urlCopied",
  invitationURL: "invitationURL",
  helpCenter: "helpCenter",
  allArticles: "allArticles",
  published: "published",
  draft: "draft",
  collections: "collections",
  helpCenterSettings: "helpCenterSettings",
  lastEditied: "lastEditied",
  hideFilter: "hideFilter",
  addFilter: "addFilter",
  newArticle: "newArticle",
  filter: "filter",
  noArticlesMatch: "noArticlesMatch",
  resetYourFilters: "resetYourFilters",
  resetFilters: "resetFilters",
  publish: "publish",
  selectLanguage: "selectLanguage",
  selectCollection: "selectCollection",
  noOfArticles: "noOfArticles",
  addCollection: "addCollection",
  editCollection: "editCollection",
  content: "content",
  styling: "styling",
  pageTitle: "pageTitle",
  english: "english",
  linkToYourHomepage: "linkToYourHomepage",
  header: "header",
  welcomeMessage: "welcomeMessage",
  color: "color",
  primaryColor: "primaryColor",
  secondaryColor: "secondaryColor",
  webChat: "webChat",
  customizeWebchat: "customizeWebchat",
  botName: "botName",
  bubbleMessage: "bubbleMessage",
  botIconUrl: "botIconUrl",
  replySoonText: "replySoonText",
  typingEnabled: "typingEnabled",
  webWidgetLabel: "webWidgetLabel",
  greetings: "greetings",
  headerText: "headerText",
  headerDescription: "headerDescription",
  welcomeText: "welcomeText",
  greetingsDialog: "greetingsDialog",
  faqs: "faqs",
  faqText: "faqText",
  popularQueries: "popularQueries",
  contentCard: "contentCard",
  enabled: "enabled",
  cardId: "cardId",
  cardText: "cardText",
  linkCard: "linkCard",
  cardUrl: "cardUrl",
  webChatSaveMessage: "webChatSaveMessage",
  webChatCopiedMessage: "webChatCopiedMessage",
  sent: "sent",
  delivered: "delivered",
  read: "read",
  deliveryRate: "deliveryRate",
  readRate: "readRate",
  failed: "failed",
  dtr: "dtr",
  more: "more",
  scheduled: "scheduled",
  ended: "ended",
  campaignStats: "campaignStats",
  waMessageTemplate: "waMessageTemplate",
  waMessageTemplateDescription: "waMessageTemplateDescription",
  learnMoreAboutTemplates: "learnMoreAboutTemplates",
  noWaTemplates: "noWaTemplates",
  newWaMessageTemplate: "newWaMessageTemplate",
  templateName: "templateName",
  messageBody: "messageBody",
  messageLanguage: "messageLanguage",
  messageBodyCopied: "messageBodyCopied",
  submitNewWaTemplate: "submitNewWaTemplate",
  newWaMessageTemplateDescription: "newWaMessageTemplateDescription",
  generalInformation: "generalInformation",
  templateNameHelpText: "templateNameHelpText",
  templateCategory: "templateCategory",
  templateCategoryHelpText: "templateCategoryHelpText",
  messageTemplate: "messageTemplate",
  messageTemplateDescription: "messageTemplateDescription",
  messageTemplateLearnMore: "messageTemplateLearnMore",
  messageBodyLabel: "messageBodyLabel",
  messageBodyDescription: "messageBodyDescription",
  messageBodyLearnMore: "messageBodyLearnMore",
  buttonOptional: "buttonOptional",
  buttonText: "buttonText",
  value: "value",
  typeOfAction: "typeOfAction",
  saveWaTemplate: "saveWaTemplate",
  waTemplateCreated: "waTemplateCreated",
  waTemplateStatusChanged: "waTemplateStatusChanged",
  messageTemplates: "messageTemplates",
  updateStatus: "updateStatus",
  statusLogs: "statusLogs",
  logsNotFound: "logsNotFound",
  changedTo: "changedTo",
  statusUpdated: "statusUpdated",
  snoozed: "snoozed",
  noMyQueueChats: "noMyQueueChats",
  noSnoozedChats: "noSnoozedChats",
  inactivityDialog: "inactivityDialog",
  inactivityDialogAt: "inactivityDialogAt",
  minutes: "minutes",
  updatedAt: "updatedAt",
  question: "question",
  answer: "answer",
  addFAQ: "addFAQ",
  editFAQ: "editFAQ",
  noFAQs: "noFAQs",
  importContact: "importContact",
  noContacts: "noContacts",
  contactsImportSuccess: "contactsImportSuccess",
  catalogs: "catalogs",
  importCatalog: "importCatalog",
  catalogSetting: "catalogSetting",
  support: "support",
  engage: "engage",
  convert: "convert",
  preferences: "preferences",
  enableAutomaticChatAcceptance: "enableAutomaticChatAcceptance",
  targetAudienceCountry: "targetAudienceCountry",
  templateType: "templateType",
  deleteTemplate: "deleteTemplate",
  waTemplateDeleted: "waTemplateDeleted",
  gettingStarted: "gettingStarted",
  setupWelcomeEdna: "setupWelcomeEdna",
  setupLetGetSetup: "setupLetGetSetup",
  setupDescription: "setupDescription",
  getSetup: "getSetup",
  needHelp: "needHelp",
  setupInboxTitle: "setupInboxTitle",
  setupInboxMessengerTitle: "setupInboxMessengerTitle",
  setupInboxMessengerDescription: "setupInboxMessengerDescription",
  setupInboxInstagramTitle: "setupInboxInstagramTitle",
  setupInboxInstagramDescription: "setupInboxInstagramDescription",
  setupInboxInstagramDescription1: "setupInboxInstagramDescription1",
  setupInboxInstagramDescription2: "setupInboxInstagramDescription2",
  setupInboxInstagramDescription3: "setupInboxInstagramDescription3",
  setupInboxInstagramDescription4: "setupInboxInstagramDescription4",
  setupInboxWhatsAppTitle: "setupInboxWhatsAppTitle",
  setupInboxWhatsAppDescription: "setupInboxWhatsAppDescription",
  setupInboxTelegramTitle: "setupInboxTelegramTitle",
  setupInboxTelegramDescription: "setupInboxTelegramDescription",
  setupInboxMSTeamsTitle: "setupInboxMSTeamsTitle",
  setupInboxMSTeamsDescription: "setupInboxMSTeamsDescription",
  setupInboxWebchatTitle: "setupInboxWebchatTitle",
  setupInboxWebchatDescription: "setupInboxWebchatDescription",
  setupHelpCenterTitle: "setupHelpCenterTitle",
  setupHelpCenterCollectionTitle: "setupHelpCenterCollectionTitle",
  setupHelpCenterCollectionDescription: "setupHelpCenterCollectionDescription",
  setupHelpCenterFAQTitle: "setupHelpCenterFAQTitle",
  setupHelpCenterFAQDescription: "setupHelpCenterFAQDescription",
  setupHelpCenterCatalogTitle: "setupHelpCenterCatalogTitle",
  setupHelpCenterCatalogDescription: "setupHelpCenterCatalogDescription",
  setupGenAITitle: "setupGenAITitle",
  setupGenAIConfigureTitle: "setupGenAIConfigureTitle",
  setupGenAIConfigureDescription: "setupGenAIConfigureDescription",
  setupGenAIPromptTitle: "setupGenAIPromptTitle",
  setupGenAIPromptDescription: "setupGenAIPromptDescription",
  setupSettingsTitle: "setupSettingsTitle",
  setupSettingsSupportTitle: "setupSettingsSupportTitle",
  setupSettingsSupportDescription: "setupSettingsSupportDescription",
  setupSettingsNPSTitle: "setupSettingsNPSTitle",
  setupSettingsNPSDescription: "setupSettingsNPSDescription",
  setupSettingsChatAssignmentTitle: "setupSettingsChatAssignmentTitle",
  setupSettingsChatAssignmentDescription:
    "setupSettingsChatAssignmentDescription",
  setupUpdatedSuccessfully: "setupUpdatedSuccessfully",
  scheduledChatClosingDialogType: "scheduledChatClosingDialogType",
  teams: "teams",
  chatAssignmentType: "chatAssignmentType",
  autoAssignChatUserTypes: "autoAssignChatUserTypes",
  tasks: "tasks",
  dueDate: "dueDate",
  editAvatar: "editAvatar",
  teammates: "teammates",
  groupChats: "groupChats",
  contactInformation: "contactInformation",
  aboutMe: "aboutMe",
  members: "members",
  orgKnowledge: "orgKnowledge",
  searchFolder: "searchFolder",
  noFolders: "noFolders",
  noDocuments: "noDocuments",
  createDocuments: "createDocuments",
  createDocumentsDescription: "createDocumentsDescription",
  storedDocuments: "storedDocuments",
  storedDocumentsDescription: "storedDocumentsDescription",
  write: "write",
  writeDescription: "writeDescription",
  upload: "upload",
  uploadDescription: "uploadDescription",
  importWebsite: "importWebsite",
  importWebsiteDescription: "importWebsiteDescription",
  source: "source",
  lastTrainedAt: "lastTrainedAt",
  comingSoon: "comingSoon",
  createFolder: "createFolder",
  editFolder: "editFolder",
  options: "options",
  ednaBot: "ednaBot",
  knowledge: "knowledge",
  configureEdnaBotsKnowledge: "configureEdnaBotsKnowledge",
  configureEdnaBotsKnowledgeDescription:
    "configureEdnaBotsKnowledgeDescription",
  activateEdnaBot: "activateEdnaBot",
  activateEdnaBotDescription: "activateEdnaBotDescription",
  enableEdnaBot: "enableEdnaBot",
  selectEdnaBotKnowledge: "selectEdnaBotKnowledge",
  aITeams: "aITeams",
  aITeamsChat: "aITeamsChat",
  noAITeams: "noAITeams",
  searchAITeam: "searchAITeam",
  createAITeam: "createAITeam",
  editAITeam: "editAITeam",
  function: "function",
  agents: "agents",
  proxyAgent: "proxyAgent",
  addedBy: "addedBy",
  org: "org",
  nonBusinessHour: "nonBusinessHour",
  aITeamChart: "aITeamChart",
  seePastTasks: "seePastTasks",
  aITeamPastTasks: "aITeamPastTasks",
  taskName: "taskName",
  replay: "replay",
  taskReplay: "taskReplay",
  noTasks: "noTasks",
  proxy: "proxy",
  canViewAllContacts: "canViewAllContacts",
  enableAutoLeadCollector: "enableAutoLeadCollector",
  autoLeadCollector: "autoLeadCollector",
  embeddingStatus: "embeddingStatus",
  lastEmbeddidAt: "lastEmbeddidAt",
  deactivateUser: "deactivateUser",
  satisfaction: "satisfaction",
  taskStatusTodo: "taskStatusTodo",
  taskStatusInProgress: "taskStatusInProgress",
  taskStatusDone: "taskStatusDone",
  repeat: "repeat",
  every: "every",
  queuePositionEnabled: "queuePositionEnabled",
  queuePositionDialog: "queuePositionDialog",
  errorLogs: "errorLogs",
  error: "error",
  errorLogPreview: "errorLogPreview",
  stackTrace: "stackTrace",
  viewOnlyAccess: "viewOnlyAccess",
  enableInactivityDialog: "enableInactivityDialog",
  inactivityDialogType: "inactivityDialogType",
  attachments: "attachments",
  uploadAttachmentText: "uploadAttachmentText",
  logoUrl: "logoUrl",
  popularArticles: "popularArticles",
  homepageConfig: "homepageConfig",
  footer: "footer",
  gotoUrlEnabled: "gotoUrlEnabled",
  gotoUrlText: "gotoUrlText",
  gotoUrl: "gotoUrl",
  communityUrlEnabled: "communityUrlEnabled",
  communityUrlText: "communityUrlText",
  communityUrl: "communityUrl",
  academyUrlEnabled: "academyUrlEnabled",
  academyUrlText: "academyUrlText",
  academyUrl: "academyUrl",
  commerce: "commerce",
  commerceBranches: "commerceBranches",
  commerceProducts: "commerceProducts",
  commerceProfile: "commerceProfile",
  gotoShopUrl: "gotoShopUrl",
  notFound: "notFound",
  signUpWelcome: "signUpWelcome",
  signOut: "signOut",
  restaurants: "restaurants",
  userLoggedIn: "userLoggedIn",
  userFound: "userFound",
  emailAlreadyInUse: "emailAlreadyInUse",
  weakPassword: "weakPassword",
  userCreated: "userCreated",
  recoveryEmailSent: "recoveryEmailSent",
  verificationEmailSent: "verificationEmailSent",
  emailNotVerified: "emailNotVerified",
  requiredField: "requiredField",
  invalidField: "invalidField",
  requiresRecentLogin: "requiresRecentLogin",
  updateSucceeded: "updateSucceeded",
  logo: "logo",
  orders: "orders",
  menu: "menu",
  branchMenu: "branchMenu",
  restaurantProfile: "restaurantProfile",
  branchProfile: "branchProfile",
  console: "console",
  coupons: "coupons",
  code: "code",
  discount: "discount",
  preparationTime: "preparationTime",
  contactEmails: "contactEmails",
  contactPhones: "contactPhones",
  notificationPhones: "notificationPhones",
  botGreetingMessage: "botGreetingMessage",
  branches: "branches",
  cash: "cash",
  creditCard: "creditCard",
  express: "express",
  inRestaurant: "inRestaurant",
  pickUp: "pickUp",
  time: "time",
  zone: "zone",
  paymentMethods: "paymentMethods",
  deliveryTypes: "deliveryTypes",
  deliveryCoverage: "deliveryCoverage",
  deliveryCoverageType: "deliveryCoverageType",
  currency: "currency",
  image: "image",
  minimumSelectedItems: "minimumSelectedItems",
  maximumSelectedItems: "maximumSelectedItems",
  perProduct: "perProduct",
  minimumValidation: "minimumValidation",
  maximumValidation: "maximumValidation",
  isModifierRequired: "isModifierRequired",
  modifierItems: "modifierItems",
  menuModifiers: "menuModifiers",
  menuCategories: "menuCategories",
  menuInternalCode: "menuInternalCode",
  menuItems: "menuItems",
  addMenuCategory: "addMenuCategory",
  onlyPositiveValues: "onlyPositiveValues",
  addMenuItem: "addMenuItem",
  addMenuModifier: "addMenuModifier",
  isRequired: "isRequired",
  isOptional: "isOptional",
  isExcluded: "isExcluded",
  min: "min",
  max: "max",
  exclude: "exclude",
  excludeItems: "excludeItems",
  include: "include",
  includeAll: "includeAll",
  excluded: "excluded",
  included: "included",
  otherPaymentMethod: "otherPaymentMethod",
  otherPaymentMethodName: "otherPaymentMethodName",
  otherPaymentMethodImage: "otherPaymentMethodImage",
  otherPaymentMethodDescription: "otherPaymentMethodDescription",
  items: "items",
  ordersRequests: "ordersRequests",
  readyForDelivery: "readyForDelivery",
  beingPrepared: "beingPrepared",
  deliveryZone: "deliveryZone",
  deliveryAddress: "deliveryAddress",
  referencePhone: "referencePhone",
  deliveryName: "deliveryName",
  deliveryNameTable: "deliveryNameTable",
  deliveryType: "deliveryType",
  order: "order",
  prepare: "prepare",
  prepareAll: "prepareAll",
  toolTipPrepareAll: "toolTipPrepareAll",
  modalTitlePrepareAll: "modalTitlePrepareAll",
  sendToDeliveryAll: "sendToDeliveryAll",
  toolTipSendToDeliveryAll: "toolTipSendToDeliveryAll",
  modalTitleSendToDeliveryAll: "modalTitleSendToDeliveryAll",
  deliveryCompleteAll: "deliveryCompleteAll",
  toolTipDeliveryCompleteAll: "toolTipDeliveryCompleteAll",
  disableNotifications: "disableNotifications",
  modalTitleDeliveryCompleteAll: "modalTitleDeliveryCompleteAll",
  disableNotificationsTT: "disableNotificationsTT",
  disableOrderTrackingTT: "disableOrderTrackingTT",
  disableOrderTracking: "disableOrderTracking",
  disableEstimateDeliveryTime: "disableEstimateDeliveryTime",
  disableEstimateDeliveryTimeTT: "disableEstimateDeliveryTimeTT",
  note: "note",
  channelInfo: "channelInfo",
  createOrder: "createOrder",
  activeOrders: "activeOrders",
  finishedOrders: "finishedOrders",
  webAdmin: "webAdmin",
  admin: "admin",
  messenger: "messenger",
  whatsApp: "whatsApp",
  cancellationReason: "cancellationReason",
  cancelOrder: "cancelOrder",
  basic: "basic",
  free: "free",
  promoCodes: "promoCodes",
  plus: "plus",
  branch: "branch",
  loading: "loading",
  couponCode: "couponCode",
  appliedCoupon: "appliedCoupon",
  subtotal: "subtotal",
  invalidPhoneNumber: "invalidPhoneNumber",
  categoryAlreadyExists: "categoryAlreadyExists",
  deactivate: "deactivate",
  askDeactivateConfirmation: "askDeactivateConfirmation",
  activate: "activate",
  askActivateConfirmation: "askActivateConfirmation",
  nothingHereYet: "nothingHereYet",
  deliveryTime: "deliveryTime",
  changesReceivedWillBeAppliedSoon: "changesReceivedWillBeAppliedSoon",
  askExcludeConfirmation: "askExcludeConfirmation",
  askIncludeConfirmation: "askIncludeConfirmation",
  arrivalTime: "arrivalTime",
  arrivalTimeReady: "arrivalTimeReady",
  arrivalMinutes: "arrivalMinutes",
  addMoreToReachMinimum: "addMoreToReachMinimum",
  orderId: "orderId",
  noDuplicates: "noDuplicates",
  orderIsAlreadySent: "orderIsAlreadySent",
  youCancelledThisOrder: "youCancelledThisOrder",
  restaurantName: "restaurantName",
  branchName: "branchName",
  textIsTooLong: "textIsTooLong",
  mustBeEqualOrGreaterThanMinimum: "mustBeEqualOrGreaterThanMinimum",
  managers: "managers",
  invalidCoupon: "invalidCoupon",
  upgradePlan: "upgradePlan",
  currentPlanUpgrade: "currentPlanUpgrade",
  basicPlanDescription: "basicPlanDescription",
  proPlanDescription: "proPlanDescription",
  plusPlanDescription: "plusPlanDescription",
  contactUs: "contactUs",
  willSendToUser: "willSendToUser",
  needAtLeastOneBranch: "needAtLeastOneBranch",
  plan: "plan",
  allowedBranches: "allowedBranches",
  youHaveBeenUpgraded: "youHaveBeenUpgraded",
  includesPreparationTime: "includesPreparationTime",
  facebookUrl: "facebookUrl",
  updatePaymentMethod: "updatePaymentMethod",
  proFeature: "proFeature",
  agreePlanUpdate: "agreePlanUpdate",
  cuisine: "cuisine",
  cuisines: {
    africanEthiopian: "cuisines.africanEthiopian",
    africanOther: "cuisines.africanOther",
    alcohol: "cuisines.alcohol",
    american: "cuisines.american",
    argentinian: "cuisines.argentinian",
    asianFusion: "cuisines.asianFusion",
    asianOther: "cuisines.asianOther",
    bbq: "cuisines.bbq",
    bakery: "cuisines.bakery",
    barOrPubFood: "cuisines.barOrPubFood",
    brazilian: "cuisines.brazilian",
    breakfastAndBrunch: "cuisines.breakfastAndBrunch",
    bubbleTea: "cuisines.bubbleTea",
    burgers: "cuisines.burgers",
    burmese: "cuisines.burmese",
    cajunOrCreole: "cuisines.cajunOrCreole",
    caribbean: "cuisines.caribbean",
    chicken: "cuisines.chicken",
    chilean: "cuisines.chilean",
    chineseCantonese: "cuisines.chineseCantonese",
    chineseHotPot: "cuisines.chineseHotPot",
    chineseNoodlesAndDumplings: "cuisines.chineseNoodlesAndDumplings",
    chineseOther: "cuisines.chineseOther",
    chineseSichuan: "cuisines.chineseSichuan",
    chineseTaiwanese: "cuisines.chineseTaiwanese",
    coffeeAndTea: "cuisines.coffeeAndTea",
    colombian: "cuisines.colombian",
    comfortFood: "cuisines.comfortFood",
    dessertOther: "cuisines.dessertOther",
    europeanOther: "cuisines.europeanOther",
    fishAndChips: "cuisines.fishAndChips",
    french: "cuisines.french",
    georgian: "cuisines.georgian",
    german: "cuisines.german",
    gourmet: "cuisines.gourmet",
    halal: "cuisines.halal",
    healthy: "cuisines.healthy",
    iceCreamAndFrozenYogurt: "cuisines.iceCreamAndFrozenYogurt",
    indian: "cuisines.indian",
    indonesian: "cuisines.indonesian",
    italian: "cuisines.italian",
    japaneseOther: "cuisines.japaneseOther",
    japaneseRamen: "cuisines.japaneseRamen",
    japaneseSushi: "cuisines.japaneseSushi",
    juiceAndSmoothies: "cuisines.juiceAndSmoothies",
    korean: "cuisines.korean",
    kosher: "cuisines.kosher",
    latinAmericanOther: "cuisines.latinAmericanOther",
    malaysian: "cuisines.malaysian",
    mediterranean: "cuisines.mediterranean",
    mexican: "cuisines.mexican",
    middleEastern: "cuisines.middleEastern",
    modernAustralian: "cuisines.modernAustralian",
    other: "cuisines.other",
    peruvian: "cuisines.peruvian",
    pizza: "cuisines.pizza",
    russian: "cuisines.russian",
    saladOrSandwiches: "cuisines.saladOrSandwiches",
    seafood: "cuisines.seafood",
    snacks: "cuisines.snacks",
    soulFood: "cuisines.soulFood",
    southern: "cuisines.southern",
    spanish: "cuisines.spanish",
    steakhouse: "cuisines.steakhouse",
    thai: "cuisines.thai",
    vegetarianOrVegan: "cuisines.vegetarianOrVegan",
    vietnamese: "cuisines.vietnamese",
    wings: "cuisines.wings",
  },
  continueWithFacebook: "continueWithFacebook",
  facebookPage: "facebookPage",
  needsAccountLinking: "needsAccountLinking",
  needsAccountCreation: "needsAccountCreation",
  facebookPermissionsDisclaimer: "facebookPermissionsDisclaimer",
  signInWithFacebookTitle: "signInWithFacebookTitle",
  botNameToolTip: "botNameToolTip",
  greetingToolTip: "greetingToolTip",
  listToolTip: "listToolTip",
  preparationTimeToolTip: "preparationTimeToolTip",
  deliveryCoverageToolTip: "deliveryCoverageToolTip",
  inRestaurantTypes: "inRestaurantTypes",
  stopSound: "stopSound",
  typeAddress: "typeAddress",
  selectPlaceOnMap: "selectPlaceOnMap",
  markBuildingEntranceForDelivery: "markBuildingEntranceForDelivery",
  deliveryNotes: "deliveryNotes",
  highestPrice: "highestPrice",
  minimumProductQty: "minimumProductQty",
  promotionType: "promotionType",
  isPromotional: "isPromotional",
  promotionParameter: "promotionParameter",
  makeSureFirstValueIsHigher: "makeSureFirstValueIsHigher",
  outOfDeliveryCoverage: "outOfDeliveryCoverage",
  unknown: "unknown",
  distance: "distance",
  export: "export",
  creating: "creating",
  received: "received",
  preparing: "preparing",
  awaitingDelivery: "awaitingDelivery",
  canceledByUser: "canceledByUser",
  freeDelivery: "freeDelivery",
  freeDeliveryOnMinItemsQuantity: "freeDeliveryOnMinItemsQuantity",
  minimumProduct: "minimumProduct",
  minimumProductError: "minimumProductError",
  branchClickHere: "branchClickHere",
  howToDocument: "howToDocument",
  ednaPayLink: "ednaPayLink",
  ednaPayLinkLong: "ednaPayLinkLong",
  verifiedAccount: "verifiedAccount",
  verifiedAccountToolTip: "verifiedAccountToolTip",
  verifiedBusiness: "verifiedBusiness",
  verifiedBusinessToolTip: "verifiedBusinessToolTip",
  verifiedEmail: "verifiedEmail",
  verifiedEmailToolTip: "verifiedEmailToolTip",
  verifiedPhone: "verifiedPhone",
  verifiedPhoneToolTip: "verifiedPhoneToolTip",
  verifiedKYC: "verifiedKYC",
  verifiedKYCToolTip: "verifiedKYCToolTip",
  createAccount: "createAccount",
  disabledEdnaPay: "disabledEdnaPay",
  ednaPay: "ednaPay",
  refresh: "refresh",
  ednaPayLinked: "ednaPayLinked",
  validatingPayment: "validatingPayment",
  paymentCompleted: "paymentCompleted",
  verifiedTermsAndConditions: "verifiedTermsAndConditions",
  refreshing: "refreshing",
  txAmount: "txAmount",
  txId: "txId",
  txfee: "txfee",
  conectEdnaPayAccount: "conectEdnaPayAccount",
  howToFix: "howToFix",
  payVerificationText: "payVerificationText",
  orderingSelectBranch: "orderingSelectBranch",
  selectBranch: "selectBranch",
  orderingBranchSelected: "orderingBranchSelected",
  you: "you",
  letsGo: "letsGo",
  generatePay: "generatePay",
  finishOrder: "finishOrder",
  subtotalSummary: "subtotalSummary",
  cancelledSummary: "cancelledSummary",
  expressPriceText: "expressPriceText",
  discountedSubtotalText: "discountedSubtotalText",
  paymentMethodInfo: "paymentMethodInfo",
  productAddedToCart: "productAddedToCart",
  orderClosing: "orderClosing",
  orderClosingAfterCancelled: "orderClosingAfterCancelled",
  orderButton: "orderButton",
  orderButtonNoTracking: "orderButtonNoTracking",
  timeDelivery: "timeDelivery",
  orderDetails: "orderDetails",
  deliveryFee: "deliveryFee",
  deleteProduct: "deleteProduct",
  addItem: "addItem",
  viewProfile: "viewProfile",
  branchesOf: "branchesOf",
  minimumSelectedModifiers: "minimumSelectedModifiers",
  maximumSelectedModifiers: "maximumSelectedModifiers",
  emptyCart: "emptyCart",
  descriptionTime: "descriptionTime",
  arrivalTimeValidation: "arrivalTimeValidation",
  groupByCategories: "groupByCategories",
  integrations: "integrations",
  deliveryIntegration: "deliveryIntegration",
  paymentIntegration: "paymentIntegration",
  hubIntegration: "hubIntegration",
  notAvailableOnYourCountryOrIntegrationDependency:
    "notAvailableOnYourCountryOrIntegrationDependency",
  storeId: "storeId",
  integrationsPro: "integrationsPro",
  trackYourOrder: "trackYourOrder",
  orderNotFound: "orderNotFound",
  orderingOrTracking: "orderingOrTracking",
  ordering: "ordering",
  trackOrder: "trackOrder",
  primaryColorTooltip: "primaryColorTooltip",
  secondaryColorTooltip: "secondaryColorTooltip",
  chooseColor: "chooseColor",
  useCurrentLocation: "useCurrentLocation",
  orderingBranchClosed: "orderingBranchClosed",
  viewInvoices: "viewInvoices",
  viewInvoice: "viewInvoice",
  invoices: "invoices",
  enjoyLoyalty: "enjoyLoyalty",
  loyaltyIntegration: "loyaltyIntegration",
  customerId: "customerId",
  customerIdPlaceHolder: "customerIdPlaceHolder",
  enjoyLoyaltyFailure: "enjoyLoyaltyFailure",
  ednaPayAdvertise: "ednaPayAdvertise",
  ednaPayAdvertiseCost: "ednaPayAdvertiseCost",
  chathubId: "chathubId",
  chathubKey: "chathubKey",
  chathubPassword: "chathubPassword",
  chathubStory: "chathubStory",
  chatBotIntegration: "chatBotIntegration",
  newOrderReceived: "newOrderReceived",
  goToClientPortal: "goToClientPortal",
  created: "created",
  updated: "updated",
  pixelId: "pixelId",
  onItsWay: "onItsWay",
  readyForPickUp: "readyForPickUp",
  readyToServe: "readyToServe",
  creditCardChargeAnowledge: "creditCardChargeAnowledge",
  parentCategory: "parentCategory",
  uiMode: "uiMode",
  compact: "compact",
  expanded: "expanded",
  seeMore: "seeMore",
  featured: "featured",
  disableBranchProductExclusionTT: "disableBranchProductExclusionTT",
  disableBranchProductExclusion: "disableBranchProductExclusion",
  showBanner: "showBanner",
  bannerImage: "bannerImage",
  hasSchedule: "hasSchedule",
  preview: "preview",
  endsIn: "endsIn",
  promotionHasEnded: "promotionHasEnded",
  emailTemplates: "emailTemplates",
  noEmailTemplates: "noEmailTemplates",
  emailTemplateCreatedMessage: "emailTemplateCreatedMessage",
  emailTemplateUpdatedMessage: "emailTemplateUpdatedMessage",
  workflows: "workflows",
  searchWorkflowCategory: "searchWorkflowCategory",
  noWorkflowCategories: "noWorkflowCategories",
  createWorkflowCategory: "createWorkflowCategory",
  editWorkflowCategory: "editWorkflowCategory",
  createWorkflow: "createWorkflow",
  editWorkflow: "editWorkflow",
  automation: "automation",
  noWorkflows: "noWorkflows",
  isActive: "isActive",
  workflowName: "workflowName",
  createdBy: "createdBy",
  subject: "subject",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
  autoAssignRequestOnNextDay: "autoAssignRequestOnNextDay",
  priority: "priority",
  high: "high",
  medium: "medium",
  low: "low",
  popularQueryType: "popularQueryType",
  popularFaqs: "popularFaqs",
  ednaBotIdCopied: "ednaBotIdCopied",
  manageKpi: "manageKpi",
  kpiName: "kpiName",
  target: "target",
  delta: "delta",
  deltaPercentage: "deltaPercentage",
  noKpis: "noKpis",
  addKpi: "addKpi",
  editKpi: "editKpi",
  createKpi: "createKpi",
  symbol: "symbol",
  createWorkspace: "createWorkspace",
  workspaceCreated: "workspaceCreated",
  popular: "popular",
  workspaces: "workspaces",
  workspaceProfile: "workspaceProfile",
  addNew: "addNew",
  workspaceBotWelcomeText: "workspaceBotWelcomeText",
  workspaceBotWelcome2Text: "workspaceBotWelcome2Text",
  workspaceBotComapanyText: "workspaceBotComapanyText",
  workspaceBotIndustryText: "workspaceBotIndustryText",
  workspaceBotTeamSizeText: "workspaceBotTeamSizeText",
  workspaceBotCreateWorkspaceText: "workspaceBotCreateWorkspaceText",
  functions: "functions",
  functionTitle: "functionTitle",
  functionDescription: "functionDescription",
  added: "added",
  addToWorkspace: "addToWorkspace",
  remove: "remove",
  aiSettings: "aiSettings",
  aiSettingsUpdated: "aiSettingsUpdated",
  industry: "industry",
  teamSize: "teamSize",
  workspaceProfileUpdated: "workspaceProfileUpdated",
  ednaBotSettings: "ednaBotSettings",
  ednaBotSettingsUpdated: "ednaBotSettingsUpdated",
  botIsConnectedWithAnotherWorkspace: "botIsConnectedWithAnotherWorkspace",
  isFunctionActive: "isFunctionActive",
  noActiveFunctionsFound: "noActiveFunctionsFound",
  businessFunction: "businessFunction",
  department: "department",
  assignUserToCurrentWorkspace: "assignUserToCurrentWorkspace",
  aITeamsSettings: "aITeamsSettings",
  aITeammates: "aITeammates",
  humanTeammates: "humanTeammates",
  operations: "operations",
  marketing: "marketing",
  sales: "sales",
  finance: "finance",
  hr: "hr",
  operationsDescription: "operationsDescription",
  marketingDescription: "marketingDescription",
  salesDescription: "salesDescription",
  financeDescription: "financeDescription",
  operationsFeatureInbox: "operationsFeatureInbox",
  operationsFeatureInboxDescription: "operationsFeatureInboxDescription",
  operationsFeatureSupport: "operationsFeatureSupport",
  operationsFeatureSupportDescription: "operationsFeatureSupportDescription",
  operationsFeatureEngagement: "operationsFeatureEngagement",
  operationsFeatureEngagementDescription:
    "operationsFeatureEngagementDescription",
  operationsFeatureConversion: "operationsFeatureConversion",
  operationsFeatureConversionDescription:
    "operationsFeatureConversionDescription",
  operationsFeatureCommerce: "operationsFeatureCommerce",
  operationsFeatureCommerceDescription: "operationsFeatureCommerceDescription",
  operationsFeatureWorkflows: "operationsFeatureWorkflows",
  operationsFeatureWorkflowsDescription:
    "operationsFeatureWorkflowsDescription",
  mkFeatureContentMarketing: "mkFeatureContentMarketing",
  mkFeatureContentMarketingDescription: "mkFeatureContentMarketingDescription",
  mkFeatureProductMarketing: "mkFeatureProductMarketing",
  mkFeatureProductMarketingDescription: "mkFeatureProductMarketingDescription",
  mkFeatureAcquisitionMarketing: "mkFeatureAcquisitionMarketing",
  mkFeatureAcquisitionMarketingDescription:
    "mkFeatureAcquisitionMarketingDescription",
  saFeatureSalesOperations: "saFeatureSalesOperations",
  saFeatureSalesOperationsDescription: "saFeatureSalesOperationsDescription",
  saFeatureSalesDevelopment: "saFeatureSalesDevelopment",
  saFeatureSalesDevelopmentDescription: "saFeatureSalesDevelopmentDescription",
  saFeatureSalesTraining: "saFeatureSalesTraining",
  saFeatureSalesTrainingDescription: "saFeatureSalesTrainingDescription",
  fnFeatureEPA: "fnFeatureEPA",
  fnFeatureEPADescription: "fnFeatureEPADescription",
  fnFeatureFinanceOperations: "fnFeatureFinanceOperations",
  fnFeatureFinanceOperationsDescription:
    "fnFeatureFinanceOperationsDescription",
  fnFeatureTaxation: "fnFeatureTaxation",
  fnFeatureTaxationDescription: "fnFeatureTaxationDescription",
  hrFeatureTalentAcquisition: "hrFeatureTalentAcquisition",
  hrFeatureTalentAcquisitionDescription:
    "hrFeatureTalentAcquisitionDescription",
  hrFeatureCompBenefits: "hrFeatureCompBenefits",
  hrFeatureCompBenefitsDescription: "hrFeatureCompBenefitsDescription",
  hrFeatureTraining: "hrFeatureTraining",
  hrFeatureTrainingDescription: "hrFeatureTrainingDescription",
  home: "home",
  utilizeItOnDesktop: "utilizeItOnDesktop",
  tickets: "tickets",
  addTicket: "addTicket",
  editTicket: "editTicket",
  list: "list",
  ticket: "ticket",
  task: "task",
  forwarderEmails: "forwarderEmails",
  mentions: "mentions",
  goToFuction: "goToFuction",
  configuration: "configuration",
  replyInThread: "replyInThread",
  thread: "thread",
  threads: "threads",
  countReply: "countReply",
  countReplies: "countReplies",
  opportunity: "opportunity",
  excludeAgents: "excludeAgents",
  calendar: "calendar",
  calendarSettings: "calendarSettings",
  eventDetails: "eventDetails",
  scheduleDetails: "scheduleDetails",
  myEvents: "myEvents",
  addEvent: "addEvent",
  share: "share",
  viewBookingPage: "viewBookingPage",
  group: "group",
  duration: "duration",
  editEvent: "editEvent",
  eventUpdated: "eventUpdated",
  schedulingDetails: "schedulingDetails",
  availableHours: "availableHours",
  bookingForm: "bookingForm",
  phone: "phone",
  calendarSettingsUpdated: "calendarSettingsUpdated",
  workspaceNotFound: "workspaceNotFound",
  booking: "booking",
  event: "event",
  selectDateAndTime: "selectDateAndTime",
  bookingSuccess: "bookingSuccess",
  archived: "archived",
  connectedText: "connectedText",
  bubbleVideoUrl: "bubbleVideoUrl",
  activity: "activity",
  hasMentionedYouIn: "hasMentionedYouIn",
  repliedInAThread: "repliedInAThread",
  noNotifications: "noNotifications",
  unreads: "unreads",
  connectedRate: "connectedRate",
  connectedChats: "connectedChats",
  linearConnectedRate: "linearConnectedRate",
  pending: "pending",
  approvedByWhatsapp: "approvedByWhatsapp",
  rejectedByWhatsapp: "rejectedByWhatsapp",
  designer: "designer",
  trigger: "trigger",
  workflowBuilder: "workflowBuilder",
  if: "if",
  and: "and",
  defaultFunction: "defaultFunction",
  events: "events",
  affiliate: "affiliate",
  startWithWorkspace: "startWithWorkspace",
  startWithWorkspaceDescription: "startWithWorkspaceDescription",
  createNewEdnaWorkspace: "createNewEdnaWorkspace",
  createNewEdnaWorkspaceDescription: "createNewEdnaWorkspaceDescription",
  enterWorkspaceId: "enterWorkspaceId",
  workspaceId: "workspaceId",
  lookingToCreateWorkspace: "lookingToCreateWorkspace",
  createNewWorkspace: "createNewWorkspace",
  findYourEdnaWorkspace: "findYourEdnaWorkspace",
  findYourEdnaWorkspaceDescription: "findYourEdnaWorkspaceDescription",
  workspaceJoinRequestSent: "workspaceJoinRequestSent",
  workspaceNoAccess: "workspaceNoAccess",
  pipeline: "pipeline",
  deal: "deal",
  estimatedCloseDate: "estimatedCloseDate",
  addDeal: "addDeal",
  stage: "stage",
  other: "other",
  probability: "probability",
  forecastCategory: "forecastCategory",
  openChat: "openChat",
  pipelineDashboard: "pipelineDashboard",
  openDeals: "openDeals",
  deals: "deals",
  closedWon: "closedWon",
  closedLost: "closedLost",
  forecastedRevenue: "forecastedRevenue",
  averageDealSize: "averageDealSize",
  average: "average",
  salesCycleLength: "salesCycleLength",
  newLeads: "newLeads",
  thisMonth: "thisMonth",
  wonRate: "wonRate",
  overall: "overall",
  salesPiplelineByAssingee: "salesPiplelineByAssingee",
  backToPipeline: "backToPipeline",
  closedAt: "closedAt",
  salesPipleline: "salesPipleline",
  sendToPipeline: "sendToPipeline",
  integration: "integration",
  createTicket: "createTicket",
  selectPage: "selectPage",
  createOrUpdateDeals: "createOrUpdateDeals",
  configureDialogFlow: "configureDialogFlow",
  setupDialogFlowDescription: "setupDialogFlowDescription",
  setupBuildCustomBot: "setupBuildCustomBot",
  setupCustomBotDescription: "setupCustomBotDescription",
  setupGenAIDescription: "setupGenAIDescription",
  conversationToday: "conversationToday",
  enableOrDisableFunction: "enableOrDisableFunction",
  guiddedTour: {
    addOperations: "guiddedTour.addOperations",
    enableFunction: "guiddedTour.enableFunction",
    configureFunction: "guiddedTour.configureFunction",
    featuresCompleted: "guiddedTour.featuresCompleted",
    activity: "guiddedTour.activity",
    dms: "guiddedTour.dms",
    inbox: "guiddedTour.inbox",
    helpCenter: "guiddedTour.helpCenter",
    chatBotSettings: "guiddedTour.chatBotSettings",
    businessSetting: "guiddedTour.businessSetting",
    workspaceProfile: "guiddedTour.workspaceProfile",
    opInbox: "guiddedTour.opInbox",
    opMyQueue: "guiddedTour.opMyQueue",
    opTeams: "guiddedTour.opTeams",
    opWorkflows: "guiddedTour.opWorkflows",
    opCrm: "guiddedTour.opCrm",
    opCampaigns: "guiddedTour.opCampaigns",
    opSales: "guiddedTour.opSales",
    opSettings: "guiddedTour.opSettings",
    calender: "guiddedTour.calender",
    calenderEvents: "guiddedTour.calenderEvents",
  },
  enable: "enable",
  disable: "disable",
  tour: "tour",
  provider: "provider",
  noIntegrations: "noIntegrations",
  pleaseSelectProvider: "pleaseSelectProvider",
  enableFacebookPageFirst: "enableFacebookPageFirst",
  editProfile: "editProfile",
  youHaveSelected: "youHaveSelected",
  contactHasActiveDeal: "contactHasActiveDeal",
  setupInboxEmailTitle: "setupInboxEmailTitle",
  setupInboxEmailDescription: "setupInboxEmailDescription",
  forwarderEmailsDescription: "forwarderEmailsDescription",
  smtp: "smtp",
  smtpConfigureDescription: "smtpConfigureDescription",
  imap: "imap",
  imapConfigureDescription: "imapConfigureDescription",
  quickReply: "quickReply",
  deadline: "deadline",
  allowConcurrentEvents: "allowConcurrentEvents",
  restrictLabelCreation: "restrictLabelCreation",
  restrictedLabelCreation: "restrictedLabelCreation",
  topDialogs: "topDialogs",
  noDialogsFound: "noDialogsFound",
  counts: "counts",
  closingRate: "closingRate",
  closingRatePerAssignee: "closingRatePerAssignee",
  avgTimePerStage: "avgTimePerStage",
  avgTime: "avgTime",
  chatsText: "chatsText",
  comments: "comments",
  addAComment: "addAComment",
  customer360Vision: "customer360Vision",
  isAContact: "isAContact",
  pipelineStage: "pipelineStage",
  userOverview: "userOverview",
  noDeal: "noDeal",
  relatedTickets: "relatedTickets",
  activities: "activities",
  taskCreated: "taskCreated",
  noTickets: "noTickets",
  noLabels: "noLabels",
  chatTransferredTimeout: "chatTransferredTimeout",
  autoAssignDealUserTypes: "autoAssignDealUserTypes",
  nameOfOtherChannel: "nameOfOtherChannel",
  activityLogs: "activityLogs",
  chatrooms: "chatrooms",
  noChatrooms: "noChatrooms",
  unarchive: "unarchive",
  cannotArchiveConnectedChat: "cannotArchiveConnectedChat",
  createChatRoom: "createChatRoom",
  participants: "participants",
  canCreateChatRooms: "canCreateChatRooms",
  templateLanguage: "templateLanguage",
  selectContentType: "selectContentType",
  body: "body",
  id: "id",
  submittedForApproval: "submittedForApproval",
  applyForWaApproval: "applyForWaApproval",
  checkStatus: "checkStatus",
  templateApprovedByWa: "templateApprovedByWa",
  templateSubmittedForApproval: "templateSubmittedForApproval",
  waTemplateCreatedAndSendForApproval: "waTemplateCreatedAndSendForApproval",
  mediaUrl: "mediaUrl",
  mediaUrlPlaceholder: "mediaUrlPlaceholder",
  templateStatusIs: "templateStatusIs",
  listButtonText: "listButtonText",
  whatsappNotEnabled: "whatsappNotEnabled",
  twilioAuthError: "twilioAuthError",
  contentSupportedChannels: "contentSupportedChannels",
  subtitle: "subtitle",
  button: "button",
  language: "language",
  configureContent: "configureContent",
  waBusinessInitiated: "waBusinessInitiated",
  waBusinessInitiatedDescription: "waBusinessInitiatedDescription",
  samples: "samples",
  joinText: "joinText",
  fieldsMapping: "fieldsMapping",
  logAgentConnected: "logAgentConnected",
  logAgentDisconnected: "logAgentDisconnected",
  logAgentWillDisconnectIn: "logAgentWillDisconnectIn",
  logUpdatedUser: "logUpdatedUser",
  logBotUpdated: "logBotUpdated",
  logBotUpdatedByEdnaBot: "logBotUpdatedByEdnaBot",
  logBoardUpdated: "logBoardUpdated",
  logBoardDeleted: "logBoardDeleted",
  logBoardCreated: "logBoardCreated",
  logQuickReplyAdded: "logQuickReplyAdded",
  logQuickReplyUpdated: "logQuickReplyUpdated",
  logQuickReplyDeleted: "logQuickReplyDeleted",
  logLabelAdded: "logLabelAdded",
  logLabelDeleted: "logLabelDeleted",
  logCampaignCreated: "logCampaignCreated",
  logUserAssignedToChat: "logUserAssignedToChat",
  logUserRejectedAssignedChat: "logUserRejectedAssignedChat",
  logUserAcceptedAssignedChat: "logUserAcceptedAssignedChat",
  logLivestreamStarted: "logLivestreamStarted",
  logLivestreamStopped: "logLivestreamStopped",
  logTicketDeleted: "logTicketDeleted",
  logContactImported: "logContactImported",
  selectLogType: "selectLogType",
  contactImportLabelDescription: "contactImportLabelDescription",
  chatWillBeClosedInQueue: "chatWillBeClosedInQueue",
  autoOpenChat360ModalWhenAgentConnects:
    "autoOpenChat360ModalWhenAgentConnects",
  download: "download",
  contactLoadingError: "contactLoadingError",
  pin: "pin",
  chooseHowLongYourPinLasts: "chooseHowLongYourPinLasts",
  youCanUnpinAtAnyTime: "youCanUnpinAtAnyTime",
  daysVar: "daysVar",
  messagePinnedSuccess: "messagePinnedSuccess",
  autoAssignRoundRobinChat: "autoAssignRoundRobinChat",
  roundRobinChatTimeout: "roundRobinChatTimeout",
  ratingRate: "ratingRate",
  ratingType: "ratingType",
  rating: "rating",
  excludedDialogsCommaSeparated: "excludedDialogsCommaSeparated",
  transferChat: "transferChat",
  noGroupsAvailable: "noGroupsAvailable",
  chat: "chat",
  agent: "agent",
  ratingAnalysis: "ratingAnalysis",
  noRatingsFound: "noRatingsFound",
  latestDeals: "latestDeals",
  customFields: "customFields",
  manageCustomFields: "manageCustomFields",
  manage: "manage",
  setBody: "setBody",
  category: "category",
  searchQuickReplies: "searchQuickReplies",
  noQuickRepliesFound: "noQuickRepliesFound",
  metaData: "metaData",
  websiteUrl: "websiteUrl",
  waUserInitiatedDescription: "waUserInitiatedDescription",
  waUserInitiated: "waUserInitiated",
  waTemplateAllowDescription: "waTemplateAllowDescription",
  inProgress: "inProgress",
  completed: "completed",
  workflowEvents: "workflowEvents",
  failedReason: "failedReason",
  workflowRunXTimes: "workflowRunXTimes",
  completedCampaigns: "completedCampaigns",
  reset: "reset",
  chatACLAgentConnected: "chatACLAgentConnected",
  chatACLAgentDisconnected: "chatACLAgentDisconnected",
  chatACLLiveChatRequested: "chatACLLiveChatRequested",
  chatACLLiveChatTimeout: "chatACLLiveChatTimeout",
  chatACLAssignedByRoundRobin: "chatACLAssignedByRoundRobin",
  chatACLTransferredByRoundRobin: "chatACLTransferredByRoundRobin",
  chatACLTransferredToAgent: "chatACLTransferredToAgent",
  chatACLTransferredBackToAgent: "chatACLTransferredBackToAgent",
  chatACLTransferredToTeam: "chatACLTransferredToTeam",
  chatACLTransferredToAgentAutoAssigned:
    "chatACLTransferredToAgentAutoAssigned",
  chatACLTransferredTimeout: "chatACLTransferredTimeout",
  chatACLAgentAcceptedTransfer: "chatACLAgentAcceptedTransfer",
  chatACLAgentRejectedTransfer: "chatACLAgentRejectedTransfer",
  chatACLDialogTriggered: "chatACLDialogTriggered",
  vsYesterday: "vsYesterday",
  assignToGroup: "assignToGroup",
  teamVolume: "teamVolume",
  generalChatAccess: "generalChatAccess",
  disableACMForChatTransfer: "disableACMForChatTransfer",
  disableCCDForChatTransfer: "disableCCDForChatTransfer",
  botTransferredFrom: "botTransferredFrom",
  detailed: "detailed",
  agentStats: "agentStats",
  newMessage: "newMessage",
  selectMessageTemplate: "selectMessageTemplate",
  agentAlreadyConnected: "agentAlreadyConnected",
  canSendMessageFromInbox: "canSendMessageFromInbox",
  canDisconnectAllChats: "canDisconnectAllChats",
  saveAsContact: "saveAsContact",
  chatAlreadyExists: "chatAlreadyExists",
  newMessageChatNotFound: "newMessageChatNotFound",
  liveChatRequest: "liveChatRequest",
  userRequestedLiveChat: "userRequestedLiveChat",
  canSkipClosingDialog: "canSkipClosingDialog",
  disconnectAndSkipClosingDialog: "disconnectAndSkipClosingDialog",
  disconnectNow: "disconnectNow",
  templates: "templates",
  setHeaders: "setHeaders",
  headers: "headers",
  apiBodyDescription: "apiBodyDescription",
  weighted: "weighted",
  chatConversionRate: "chatConversionRate",
  matching: "matching",
  submit: "submit",
  forgotPassword: "forgotPassword",
  backToLogin: "backToLogin",
  resetPasswordEmailSent: "resetPasswordEmailSent",
  capture: "capture",
  browse: "browse",
  captureResult: "captureResult",
  switchCamera: "switchCamera",
  testSMTP: "testSMTP",
  testIMAP: "testIMAP",
  receiverEmail: "receiverEmail",
  forward: "forward",
  forwardMessageTo: "forwardMessageTo",
  noResult: "noResult",
  messageForwardSuccess: "messageForwardSuccess",
  disableChatHistory: "disableChatHistory",
  enableGuestMode: "enableGuestMode",
  addGuestUser: "addGuestUser",
  messageGuestUserUpdateSuccess: "messageGuestUserUpdateSuccess",
  openFullView: "openFullView",
  reason: "reason",
  lostDealReasons: "lostDealReasons",
  noLostDealReasonsFound: "noLostDealReasonsFound",
  noEventsFound: "noEventsFound",
  fileLargerThanAllowedSize: "fileLargerThanAllowedSize",
  largeFileUpload: "largeFileUpload",
  setConfidential: "setConfidential",
  removeConfidential: "removeConfidential",
  chatAnalytics: "chatAnalytics",
  salesAnalytics: "salesAnalytics",
  campaignAnalytics: "campaignAnalytics",
  pipelineAssignee: "pipelineAssignee",
  setDefaultTheme: "setDefaultTheme",
  commentWillBeAvailableAfterSaving: "commentWillBeAvailableAfterSaving",
  restrictAllAnalytics: "restrictAllAnalytics",
  updateDeal: "updateDeal",
  updateAndDisconnect: "updateAndDisconnect",
  dealUpdateRequired: "dealUpdateRequired",
  backupLivecardId: "backupLivecardId",
  backupLivecardTimeout: "backupLivecardTimeout",
  transferedToBackupTeam: "transferedToBackupTeam",
  markAsDefault: "markAsDefault",
  sorting: "sorting",
  howManyConcurrentEvents: "howManyConcurrentEvents",
  averageSatisfactionRate: "averageSatisfactionRate",
};

export default locale;
